var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { START_FETCH_STATUS } from '@/shared/api/constants/startFetch.status';
import { BASE_FETCH_STATUS } from '@/shared/api/constants/baseFetch.status';
var initialState = {
    news: [],
    currentNews: null,
    longreadsNews: null,
    shortreadsNews: null,
    newsStatus: BASE_FETCH_STATUS,
    trends: null,
    followTrends: null,
    trendsStatus: BASE_FETCH_STATUS,
    followTrendsStatus: BASE_FETCH_STATUS,
    newsPresets: null,
    singleNews: null,
    singleNewsStatus: BASE_FETCH_STATUS,
    twoTickerNews: null,
    twoTickerNewsStatus: BASE_FETCH_STATUS,
};
var getNews = createAsyncThunk('newsSlice/getNews', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var queryParams, resp, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                queryParams = [
                    "limit=".concat(payload.limit),
                    "offset=".concat(payload.offset),
                    payload.tickers ? "tickers=".concat(payload.tickers) : '',
                    payload.topics ? "topics=".concat(payload.topics) : '',
                    payload.companies ? "companies=".concat(payload.companies) : '',
                    payload.keywords ? "keywords=".concat(payload.keywords) : '',
                    payload.start_date ? "start_date=".concat(payload.start_date) : '',
                    payload.end_date ? "end_date=".concat(payload.end_date) : '',
                    payload.read_type ? "read_type=".concat(payload.read_type) : '',
                ].filter(Boolean).join('&');
                return [4 /*yield*/, thunkAPI.extra.api.get("/news?".concat(queryParams))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data.news];
            case 2:
                e_1 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_1)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getCurrentNews = createAsyncThunk('newsSlice/getCurrentNews', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/news/".concat(payload.id, "/"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_2 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_2)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getLongreadsNews = createAsyncThunk('newsSlice/getLongreadsNews', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/news/longreads/?limit=".concat(payload.limit, "&offset=").concat(payload.offset, "/"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_3 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_3)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getShortreadsNews = createAsyncThunk('newsSlice/getShortreadsNews', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/news/shortreads/?limit=".concat(payload.limit, "&offset=").concat(payload.offset, "/"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_4 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_4)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getTrends = createAsyncThunk('newsSlice/getTrends', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var queryParams, resp, e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                queryParams = [
                    payload.period ? "period=".concat(payload.period) : '',
                    payload.object_type ? "object_type=".concat(payload.object_type) : '',
                    "limit=".concat(payload.limit),
                    "offset=".concat(payload.offset),
                ].filter(Boolean).join('&');
                return [4 /*yield*/, thunkAPI.extra.api.get("/news/trends/?".concat(queryParams))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_5 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_5)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getFollowTrends = createAsyncThunk('newsSlice/getFollowTrends', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var queryParams, resp, e_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                queryParams = [
                    payload.period ? "period=".concat(payload.period) : '',
                    payload.object_type ? "object_type=".concat(payload.object_type) : '',
                    "limit=".concat(payload.limit),
                    "offset=".concat(payload.offset),
                ].filter(Boolean).join('&');
                return [4 /*yield*/, thunkAPI.extra.api.get("/news/trends/?".concat(queryParams))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_6 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_6)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getExistingKeywords = createAsyncThunk('newsSlice/getExistingKeywords', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var queryParams, resp, e_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                queryParams = [
                    "limit=".concat(payload.limit),
                    "offset=".concat(payload.offset),
                ].filter(Boolean).join('&');
                return [4 /*yield*/, thunkAPI.extra.api.get("/news/existing_keywords?".concat(queryParams))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_7 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_7)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getTickers = createAsyncThunk('newsSlice/getTickers', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var queryParams, resp, e_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                queryParams = [
                    "limit=".concat(payload.limit),
                    "offset=".concat(payload.offset),
                ].filter(Boolean).join('&');
                return [4 /*yield*/, thunkAPI.extra.api.get("/tickers/?".concat(queryParams))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_8 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_8)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getNewsPresets = createAsyncThunk('newsSlice/getNewsPresets', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var queryParams, response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                queryParams = [
                    "limit=".concat(payload.limit),
                    "offset=".concat(payload.offset),
                ].filter(Boolean).join('&');
                return [4 /*yield*/, thunkAPI.extra.api.get("/news-presets/?".concat(queryParams))];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data.results];
            case 2:
                error_1 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(error_1)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var createNewsPreset = createAsyncThunk('newsSlice/createNewsPreset', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.post('/news-presets/', payload)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                error_2 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(error_2)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var deleteNewsPreset = createAsyncThunk('newsSlice/deleteNewsPreset', function (presetId, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.delete("/news-presets/".concat(presetId, "/"))];
            case 1:
                _a.sent();
                return [2 /*return*/, presetId];
            case 2:
                error_3 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(error_3)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var toggleNewsPresetNotification = createAsyncThunk('newsSlice/toggleNewsPresetNotification', function (_a, thunkAPI) {
    var id = _a.id, currentStatus = _a.currentStatus;
    return __awaiter(void 0, void 0, void 0, function () {
        var response, error_4;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, thunkAPI.extra.api.patch("/news-presets/".concat(id, "/"), {
                            notifications_enabled: !currentStatus,
                        })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_4 = _b.sent();
                    return [2 /*return*/, thunkAPI.rejectWithValue(error_4)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
var getTwoTickerNews = createAsyncThunk('newsSlice/getTwoTickerNews', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, newsList, newsDetailsPromises, newsDetails, e_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!payload.tickerName) {
                    return [2 /*return*/, null];
                }
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/news?limit=3&offset=0&tickers=".concat(payload.tickerName))];
            case 2:
                response = _a.sent();
                newsList = response.data.news;
                if (!newsList || newsList.length === 0) {
                    return [2 /*return*/, null];
                }
                newsList = newsList.filter(function (news) { return news.id !== payload.excludeId; });
                if (newsList.length === 0) {
                    return [2 /*return*/, null];
                }
                if (newsList.length === 3) {
                    newsList = newsList.slice(0, 2);
                }
                newsDetailsPromises = newsList.map(function (news) { return __awaiter(void 0, void 0, void 0, function () {
                    var newsResponse;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, thunkAPI.extra.api.get("/news/".concat(news.id, "/"))];
                            case 1:
                                newsResponse = _a.sent();
                                return [2 /*return*/, newsResponse.data];
                        }
                    });
                }); });
                return [4 /*yield*/, Promise.all(newsDetailsPromises)];
            case 3:
                newsDetails = _a.sent();
                return [2 /*return*/, newsDetails];
            case 4:
                e_9 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_9)];
            case 5: return [2 /*return*/];
        }
    });
}); });
var newsSlice = createSlice({
    name: 'newsSlice',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder.addCase(getNews.pending, function (state) {
            state.newsStatus = START_FETCH_STATUS;
        });
        builder.addCase(getNews.rejected, function (state, _a) {
            var payload = _a.payload;
            state.newsStatus.isLoading = false;
            state.newsStatus.error = payload;
        });
        builder.addCase(getNews.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.newsStatus.isLoading = false;
            state.news = payload;
        });
        builder.addCase(getCurrentNews.pending, function (state) {
            state.singleNewsStatus = START_FETCH_STATUS;
        });
        builder.addCase(getCurrentNews.rejected, function (state, _a) {
            var payload = _a.payload;
            state.singleNewsStatus.isLoading = false;
            state.singleNewsStatus.error = payload;
        });
        builder.addCase(getCurrentNews.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.singleNewsStatus.isLoading = false;
            state.singleNews = payload;
        });
        builder.addCase(getLongreadsNews.pending, function (state) {
            state.newsStatus = START_FETCH_STATUS;
        });
        builder.addCase(getLongreadsNews.rejected, function (state, _a) {
            var payload = _a.payload;
            state.newsStatus.isLoading = false;
            state.newsStatus.error = payload;
        });
        builder.addCase(getLongreadsNews.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.newsStatus.isLoading = false;
            state.longreadsNews = payload;
        });
        builder.addCase(getShortreadsNews.pending, function (state) {
            state.newsStatus = START_FETCH_STATUS;
        });
        builder.addCase(getShortreadsNews.rejected, function (state, _a) {
            var payload = _a.payload;
            state.newsStatus.isLoading = false;
            state.newsStatus.error = payload;
        });
        builder.addCase(getShortreadsNews.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.newsStatus.isLoading = false;
            state.shortreadsNews = payload;
        });
        builder.addCase(getTrends.pending, function (state) {
            state.trendsStatus = START_FETCH_STATUS;
        });
        builder.addCase(getTrends.rejected, function (state, _a) {
            var payload = _a.payload;
            state.trendsStatus.isLoading = false;
            state.trendsStatus.error = payload;
        });
        builder.addCase(getTrends.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.trendsStatus.isLoading = false;
            state.trends = payload;
        });
        builder.addCase(getFollowTrends.pending, function (state) {
            state.followTrendsStatus = START_FETCH_STATUS;
        });
        builder.addCase(getFollowTrends.rejected, function (state, _a) {
            var payload = _a.payload;
            state.followTrendsStatus.isLoading = false;
            state.followTrendsStatus.error = payload;
        });
        builder.addCase(getFollowTrends.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.followTrendsStatus.isLoading = false;
            state.followTrends = payload;
        });
        builder.addCase(getNewsPresets.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.newsPresets = payload;
        });
        builder.addCase(createNewsPreset.fulfilled, function (state, _a) {
            var payload = _a.payload;
            if (state.newsPresets) {
                state.newsPresets = __spreadArray(__spreadArray([], state.newsPresets, true), [payload], false);
            }
            else {
                state.newsPresets = [payload];
            }
        });
        builder.addCase(getTwoTickerNews.pending, function (state) {
            state.twoTickerNewsStatus = START_FETCH_STATUS;
        });
        builder.addCase(getTwoTickerNews.rejected, function (state, _a) {
            var payload = _a.payload;
            state.twoTickerNewsStatus.isLoading = false;
            state.twoTickerNewsStatus.error = payload;
        });
        builder.addCase(getTwoTickerNews.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.twoTickerNewsStatus.isLoading = false;
            state.twoTickerNews = payload;
        });
        builder.addCase(deleteNewsPreset.fulfilled, function (state, _a) {
            var payload = _a.payload;
            if (state.newsPresets) {
                state.newsPresets = state.newsPresets.filter(function (preset) { return preset.id !== payload; });
            }
        });
        builder.addCase(toggleNewsPresetNotification.fulfilled, function (state, _a) {
            var payload = _a.payload;
            if (state.newsPresets) {
                state.newsPresets = state.newsPresets.map(function (preset) {
                    return preset.id === payload.id ? __assign(__assign({}, preset), { notifications_enabled: payload.notifications_enabled }) : preset;
                });
            }
        });
    },
});
export default newsSlice.reducer;
export { getNews, getCurrentNews, getLongreadsNews, getShortreadsNews, getTrends, getExistingKeywords, getTickers, getNewsPresets, createNewsPreset, getTwoTickerNews, deleteNewsPreset, toggleNewsPresetNotification, getFollowTrends, };
