var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as S from './style';
import { Navigation } from '@/features/ui/Navigation/Navigation';
import { HeaderProfile } from '@/features/ui/HeaderProfile/HeaderProfile';
import { useEffect, useState } from 'react';
import { useTypedSelector } from '@/shared/hooks/useTypesSelector/useTypesSelector';
import { getProfile } from '@/entities/user/model/userModelSlice';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { useHistory, useLocation } from 'react-router-dom';
import { RoutePath } from '@/shared/config/routeConfig/routeConfig';
var Header = function () {
    var dispatch = useTypedDispatch();
    var history = useHistory();
    var location = useLocation();
    var path = location.pathname;
    var currentTheme = useTypedSelector(function (state) { return state.themeReducer.theme; });
    var _a = useTypedSelector(function (state) { return state.userReducer; }), userData = _a.userData, isAuth = _a.isAuth;
    var _b = useState(false), scrolling = _b[0], setScrolling = _b[1];
    var _c = useState(false), isMenuOpen = _c[0], setIsMenuOpen = _c[1];
    var handleScroll = function () {
        if (window.scrollY > 40) {
            setScrolling(true);
        }
        else {
            setScrolling(false);
        }
    };
    var openMenu = function () {
        setIsMenuOpen(!isMenuOpen);
    };
    var closeMenus = function () {
        setIsMenuOpen(false);
    };
    useEffect(function () {
        if (isAuth) {
            dispatch(getProfile(''));
        }
    }, [isAuth, dispatch]);
    useEffect(function () {
        window.addEventListener('scroll', handleScroll);
        return function () { return window.removeEventListener('scroll', handleScroll); };
    }, []);
    useEffect(function () {
        var htmlElement = document.documentElement;
        if (isMenuOpen) {
            htmlElement.style.overflow = 'hidden';
        }
        else {
            htmlElement.style.overflow = 'auto';
        }
        return function () {
            htmlElement.style.overflow = 'auto';
        };
    }, [isMenuOpen]);
    if (path === '/') {
        return (_jsxs(_Fragment, { children: [_jsx(S.BurgerMenuOverlay, __assign({ isOpen: isMenuOpen, css: { background: currentTheme === 'dark' ? 'rgba(10, 10, 10, 0.8)' : '' } }, { children: _jsx(S.BurgerMenuContent, { children: _jsx(Navigation, { menu: isMenuOpen ? 'mobileMenu' : undefined, token: isAuth, onItemClick: closeMenus }) }) })), _jsx(S.HeaderWrapperWithoutAuth, __assign({ id: "header", scrolling: scrolling, isMenuOpen: isMenuOpen, css: { backgroundColor: isMenuOpen ? currentTheme === 'dark' ? 'rgba(10, 10, 10, 0.8) !important' : '' : '' } }, { children: _jsxs(S.HeaderContainer, { children: [_jsx(S.LogoContainer, { children: _jsxs("div", __assign({ onClick: function () {
                                        closeMenus();
                                        setTimeout(function () {
                                            history.push(RoutePath.main);
                                        }, 250);
                                    } }, { children: [_jsx(S.DesktopLogo, {}), _jsx(S.MobileLogo, { fill: currentTheme === 'dark' ? 'white' : 'black' })] })) }), _jsxs(S.BurgerContainer, __assign({ onClick: openMenu, "aria-label": "Toggle menu" }, { children: [_jsx(S.Line1, { active: isMenuOpen, css: { backgroundColor: currentTheme === 'dark' ? 'white' : 'black' } }), _jsx(S.Line2, { active: isMenuOpen, css: { backgroundColor: currentTheme === 'dark' ? 'white' : 'black' } }), _jsx(S.Line3, { active: isMenuOpen, css: { backgroundColor: currentTheme === 'dark' ? 'white' : 'black' } })] })), _jsx(S.NavContainer, { children: _jsx(Navigation, { token: isAuth }) }), _jsx(S.ProfileContainer, { children: _jsx(HeaderProfile, { userData: userData, token: isAuth }) })] }) }))] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(S.BurgerMenuOverlay, __assign({ isOpen: isMenuOpen, css: { backgroundColor: currentTheme === 'dark' ? 'rgba(0, 0, 0, 0.7)' : '' } }, { children: _jsx(S.BurgerMenuContent, { children: _jsx(Navigation, { menu: isMenuOpen ? 'mobileMenu' : undefined, token: isAuth, onItemClick: closeMenus }) }) })), _jsx(S.HeaderWrapper, __assign({ id: "header", isMenuOpen: isMenuOpen, css: { backgroundColor: isMenuOpen ? currentTheme === 'dark' ? 'rgba(10, 10, 10, 0.8) !important' : '' : '' } }, { children: _jsxs(S.HeaderContainer, { children: [_jsx(S.LogoContainer, { children: _jsxs("div", __assign({ onClick: function () {
                                    closeMenus();
                                    setTimeout(function () {
                                        history.push(RoutePath.main);
                                    }, 250);
                                } }, { children: [_jsx(S.DesktopLogo, {}), _jsx(S.MobileLogo, { fill: currentTheme === 'dark' ? 'white' : 'black' })] })) }), _jsxs(S.BurgerContainer, __assign({ onClick: openMenu, "aria-label": "Toggle menu" }, { children: [_jsx(S.Line1, { active: isMenuOpen, css: { backgroundColor: currentTheme === 'dark' ? 'white' : 'black' } }), _jsx(S.Line2, { active: isMenuOpen, css: { backgroundColor: currentTheme === 'dark' ? 'white' : 'black' } }), _jsx(S.Line3, { active: isMenuOpen, css: { backgroundColor: currentTheme === 'dark' ? 'white' : 'black' } })] })), _jsx(S.NavContainer, { children: _jsx(Navigation, { token: isAuth }) }), _jsx(S.ProfileContainer, { children: _jsx(HeaderProfile, { userData: userData, token: isAuth }) })] }) }))] }));
};
export { Header };
