import { styled } from '@/app/styles/mainStyles';
import { ReactComponent as Logo } from '@/shared/assets/icons/Qats.svg';
import { ReactComponent as LogoGreen } from '@/shared/assets/icons/greenLOGO.svg';
export var MainFooterContainer = styled('div', {
    boxSizing: 'border-box',
    paddingTop: '120px',
    background: '#832323',
    '@mobile': {
        paddingTop: '30px',
    },
    variants: {
        background: {
            white: {
                backgroundColor: '$white',
            },
            gray: {
                backgroundColor: '$bgSecondary',
            },
        },
    },
});
export var FooterWrapper = styled('div', {
    position: 'relative',
    boxSizing: 'border-box',
    width: '100%',
    height: '256px',
    margin: '0 auto',
    background: '$bgSecondary',
    zIndex: 9,
    '@mobile': {
        height: '448px',
    },
    '@tablet': {
        height: '352px',
    },
});
export var FooterText = styled('p', {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '28px',
    color: '$inputBorderHover',
    '@mobile': {
        fontSize: '16px',
        lineHeight: '24px',
    },
});
export var Footer = styled('div', {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    maxWidth: '1440px',
    width: '100%',
    padding: '44px 96px',
    margin: '0 auto',
    gap: '24px',
    '@mobile': {
        flexDirection: 'column',
        padding: '32px 16px',
    },
    '@tablet': {
        flexDirection: 'column',
        padding: '32px 16px',
    },
});
export var FooterTopRow = styled('div', {
    display: 'flex',
    gap: '41px',
    '@mobile': {
        flexDirection: 'column',
        gap: '12px',
    },
    '@tablet': {
        flexDirection: 'column',
        gap: '12px',
    },
});
export var FooterLogo = styled('div', {
    display: 'inline-flex',
    color: '$white',
    cursor: 'pointer',
});
export var LogoIcon = styled(Logo, {
    color: '$primaryText',
    display: 'block',
});
export var DesktopLogoGreen = styled(LogoGreen, {
    display: 'flex',
    alignItems: 'center',
    color: '#FFF',
});
export var Divider = styled('div', {
    boxSizing: 'border-box',
    width: '100%',
    height: '1px',
    backgroundColor: '$divider',
});
export var FooterBottomRow = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    '@mobile': {
        flexDirection: 'column',
    },
    '@tablet': {
        flexDirection: 'column',
    },
});
export var InfoTextWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
});
export var BottomText = styled(FooterText, {
    color: '$textSecondary',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 400,
    '&:last-child': {
        marginBottom: 0,
    },
    '@mobile': {
        fontSize: '14px',
        lineHeight: '20px',
    },
    '@tablet': {
        fontSize: '14px',
        lineHeight: '20px',
    },
});
export var CompanyDesktop = styled(FooterText, {
    color: '$textSecondary',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 400,
    '&:last-child': {
        marginBottom: 0,
    },
    '@mobile': {
        display: 'none',
    },
    '@tablet': {
        display: 'none',
    },
});
export var CompanyMobile = styled(FooterText, {
    color: '$textSecondary',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    '&:last-child': {
        marginBottom: 0,
    },
    '@desktop': {
        display: 'none',
    },
});
export var FooterSocialMedia = styled('div', {
    color: '$white',
    display: 'flex',
    alignItems: 'start',
    gap: '16px',
    '@mobile': {
        margin: '24px 0 24px 0',
    },
    '@tablet': {
        margin: '24px 0 24px 0',
    },
});
export var FooterNav = styled('div', {
    display: 'flex',
    width: '100%',
    '@mobile': {
        paddingTop: '0',
    },
});
export var FooterLinksWrapper = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '@mobile': {
        flexWrap: 'wrap',
        gap: '16px',
        justifyContent: 'unset',
    },
    '@tablet': {
        flexWrap: 'wrap',
        gap: '16px',
        justifyContent: 'unset',
    },
});
export var FooterLinks = styled('ul', {
    listStyleType: 'none',
    display: 'flex',
    margin: '0',
    padding: '0',
    alignItems: 'center',
    gap: '16px',
    '@mobile': {
        flexWrap: 'wrap',
    },
    '@tablet': {
        flexWrap: 'wrap',
    },
});
export var FooterLink = styled('li', {
    listStyleType: 'none',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    color: '$primaryText',
    cursor: 'pointer',
    transition: 'opacity .2s',
    '&:hover': {
        opacity: '.7',
    },
    '@mobile': {
        fontSize: '14px',
        lineHeight: '20px',
    },
    '@tablet': {
        fontSize: '14px',
        lineHeight: '20px',
    },
});
