import { styled } from '@/app/styles/mainStyles';
export var InterfaceChip = styled('button', {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '6px 12px',
    borderRadius: '16px',
    cursor: 'pointer',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    border: 'none',
    justifyContent: 'center',
    outline: 'none',
    fontFamily: '$montserrat',
    height: '38px',
    variants: {
        type: {
            selected: {
                backgroundColor: '$controlsBgPrimaryDefault',
                transition: 'backgroundColor 0.1s ease',
                color: '$textInvertedWhite',
                fontSize: '14px',
                fontWeight: 400,
            },
            notSelected: {
                background: 'none',
                color: '$primaryText',
                fontSize: '14px',
                fontWeight: 400,
                border: '1px solid $controlsStrokeDefault',
                transition: 'background 0.2s ease',
                '&:hover': {
                    background: '$controlsBgSecondaryHover',
                },
            },
        },
    },
});
