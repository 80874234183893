var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_FETCH_STATUS } from '@/shared/api/constants/baseFetch.status';
import { START_FETCH_STATUS } from '@/shared/api/constants/startFetch.status';
var initialState = {
    logs: [],
    logsStatus: BASE_FETCH_STATUS,
    positions: { positions: [] },
    positionsStatus: BASE_FETCH_STATUS,
    orders: { orders: [] },
    ordersStatus: BASE_FETCH_STATUS,
};
var saveToLocalStorage = function (key, data) {
    localStorage.setItem(key, JSON.stringify({ data: data, timestamp: Date.now() }));
};
var getPositions = createAsyncThunk('widgetsSlice/getPositions', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/strategy-workspace/".concat(payload.id, "/positions/"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_1 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_1)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getOrders = createAsyncThunk('widgetsSlice/getOrders', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/strategy-workspace/".concat(payload.id, "/orders/"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_2 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_2)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getLogs = createAsyncThunk('widgetsSlice/getLogs', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/strategy-workspace/".concat(payload.id, "/logs/"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_3 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_3)];
            case 3: return [2 /*return*/];
        }
    });
}); });
function isObjectWithStrings(obj) {
    return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
}
var widgetsSlice = createSlice({
    name: 'widgetsSlice',
    initialState: initialState,
    reducers: {
        clearPositions: function (state) {
            state.positions = { positions: [] };
        },
        clearOrders: function (state) {
            state.orders = { orders: [] };
        },
        clearLogs: function (state) {
            state.logs = [];
        },
        resetFetchStatuses: function (state) {
            state.logsStatus = BASE_FETCH_STATUS;
            state.positionsStatus = BASE_FETCH_STATUS;
            state.ordersStatus = BASE_FETCH_STATUS;
        },
    },
    extraReducers: function (builder) {
        builder.addCase(getPositions.pending, function (state) {
            state.positionsStatus.isLoading = true;
        });
        builder.addCase(getPositions.fulfilled, function (state, _a) {
            var payload = _a.payload, meta = _a.meta;
            state.positions = payload;
            state.positionsStatus.isLoading = false;
            var strategyId = meta.arg.id;
            saveToLocalStorage("positions_".concat(strategyId), payload);
        });
        builder.addCase(getPositions.rejected, function (state, _a) {
            var payload = _a.payload;
            state.positionsStatus.error = payload;
            state.positionsStatus.isLoading = false;
        });
        builder.addCase(getOrders.pending, function (state) {
            state.ordersStatus.isLoading = true;
        });
        builder.addCase(getOrders.fulfilled, function (state, _a) {
            var payload = _a.payload, meta = _a.meta;
            state.orders = payload;
            state.ordersStatus.isLoading = false;
            var strategyId = meta.arg.id;
            saveToLocalStorage("orders_".concat(strategyId), payload);
        });
        builder.addCase(getOrders.rejected, function (state, _a) {
            var payload = _a.payload;
            state.ordersStatus.error = payload;
            state.ordersStatus.isLoading = false;
        });
        builder.addCase(getLogs.pending, function (state) {
            state.logsStatus = START_FETCH_STATUS;
        });
        builder.addCase(getLogs.fulfilled, function (state, _a) {
            var payload = _a.payload, meta = _a.meta;
            var newLogs = Object.entries(payload).flatMap(function (_a) {
                var blockName = _a[0], companies = _a[1];
                if (isObjectWithStrings(companies)) {
                    return Object.entries(companies).map(function (_a) {
                        var tickerName = _a[0], data = _a[1];
                        return ({
                            block_name: blockName,
                            ticker_name: tickerName,
                            data: data,
                        });
                    });
                }
                return [];
            });
            state.logs = __spreadArray(__spreadArray([], newLogs, true), state.logs, true);
            state.logsStatus.isLoading = false;
            var strategyId = meta.arg.id;
            saveToLocalStorage("logs_".concat(strategyId), state.logs);
        });
    },
});
export default widgetsSlice.reducer;
export var clearPositions = (_a = widgetsSlice.actions, _a.clearPositions), clearOrders = _a.clearOrders, clearLogs = _a.clearLogs, resetFetchStatuses = _a.resetFetchStatuses;
export { getPositions, getOrders, getLogs };
