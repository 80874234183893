import { styled } from '@stitches/react';
export var TabsWrapper = styled('div', {
    boxSizing: 'border-box',
    lineHeight: 0,
    verticalAlign: 'center',
    display: 'block',
    borderRadius: '0px',
    border: 'none',
});
export var TabsInput = styled('input', {
    transition: 'background-color .2s',
    display: 'none',
    '&:checked + label': {
        background: '$textInvertedBlack',
        color: '$textInvertedWhite',
        borderBottom: 'none',
    },
});
export var TabsContent = styled('label', {
    boxSizing: 'border-box',
    cursor: 'pointer',
    fontWeight: 500,
    userSelect: 'none',
    transition: 'all .2s',
    width: '100%',
    height: '32px',
    fontSize: '14px',
    padding: '4px 8px 4px 16px ',
    lineHeight: '22px',
    borderRadius: '0px',
    color: '$primaryText',
    backgroundColor: '$bgSecondary',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
});
export var TabsLabel = styled('span', {
    boxSizing: 'border-box',
    userSelect: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});
