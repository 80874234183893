export var plansMock = [
    {
        withFrom: false,
        name: 'Decisionmaking',
        subName: 'Decisionmaking',
        description: 'Partial access to the «Workspace» and extended access to the «News» block',
        price: '$50',
        includeFeatures: [
            'Filters&key words search among news',
            'Saving favourite news queries & receiving alerts on new articles',
            'Workspace usage for personal needs',
        ],
        blueColor: false,
        withIcon: true,
        withButtonDescription: false,
    },
    {
        name: 'Full pack',
        subName: 'All-in-one',
        description: 'All tools at an attractive price',
        price: '$60',
        includeFeatures: [
            'All features from «Decisionmaking» and «Strategies» plans together',
        ],
        greenColor: true,
        withIcon: false,
    },
    {
        name: 'Strategies',
        subName: 'Strategies',
        description: 'Possibility to activate ready-to-use strategies and to create your own strategies',
        price: '$50',
        includeFeatures: [
            'Usage of up to 3 strategies',
            'Creation of your own strategy',
        ],
        blueColor: false,
        withIcon: true,
    },
];
export var FAQitems = [
    {
        id: 'item-1',
        title: 'What is QATS and how to get started?',
        content: 'QATS is an advanced no-code system that uses smart blocks and flowcharts to automate and optimize trading strategies. To get started, sign up on our website, create an account, and follow the setup instructions.',
    },
    {
        id: 'item-2',
        title: 'What are smart blocks and can I customize my trading algorithms?',
        content: 'Smart blocks are modular components of the QATS system that allow you to compare variables and create custom algorithms. Yes, QATS enables extensive customization to suit your trading preferences.',
    },
    { id: 'item-3', title: 'What markets are supported by QATS?', content: 'QATS supports a range of markets, including cryptocurrencies, stocks, currency and commodities, allowing you to diversify your trading strategies.' },
    { id: 'item-4', title: 'What types of trading strategies can I implement with QATS?', content: 'You can implement various strategies, including trend following, arbitrage, and market-making, tailored to your specific goals. QATS provides the flexibility to adapt strategies as needed.' },
];
export var strategiesData = [
    {
        id: 1,
        name: 'Crypto futures',
        author: {
            username: 'Quantity',
            profile_image: 'https://sneg.top/uploads/posts/2023-06/1687994769_sneg-top-p-avatarka-s-voprositelnim-znakom-krasivo-12.jpg',
        },
        image: 'https://dividendoff.net/wp-content/uploads/2019/04/2-4.jpg',
        forecast: 96,
        brokers: [],
        risk_level: 'high',
    },
    {
        id: 2,
        name: 'Stock options',
        author: {
            username: 'TraderJoe',
            profile_image: 'https://sneg.top/uploads/posts/2023-06/1687994769_sneg-top-p-avatarka-s-voprositelnim-znakom-krasivo-12.jpg',
        },
        image: 'https://dividendoff.net/wp-content/uploads/2019/04/2-4.jpg',
        forecast: 87,
        brokers: [],
        risk_level: 'medium',
    },
    {
        id: 3,
        name: 'Tech Growth Stocks',
        author: {
            username: 'InvestorJane',
            profile_image: 'https://sneg.top/uploads/posts/2023-06/1687994769_sneg-top-p-avatarka-s-voprositelnim-znakom-krasivo-12.jpg',
        },
        image: 'https://dividendoff.net/wp-content/uploads/2019/04/2-4.jpg',
        forecast: 15,
        brokers: [],
        risk_level: 'medium',
    },
    {
        id: 4,
        name: 'Real Estate Investment Trusts (REITs)',
        author: {
            username: 'PropertyPro',
            profile_image: 'https://sneg.top/uploads/posts/2023-06/1687994769_sneg-top-p-avatarka-s-voprositelnim-znakom-krasivo-12.jpg',
        },
        image: 'https://dividendoff.net/wp-content/uploads/2019/04/2-4.jpg',
        forecast: 8,
        brokers: ['broker 1', 'broker 2'],
        risk_level: 'low',
    },
    {
        id: 5,
        name: 'Global Macro Hedge Fund Strategy',
        author: {
            username: 'HedgeFundGuru',
            profile_image: 'https://sneg.top/uploads/posts/2023-06/1687994769_sneg-top-p-avatarka-s-voprositelnim-znakom-krasivo-12.jpg',
        },
        image: 'https://dividendoff.net/wp-content/uploads/2019/04/2-4.jpg',
        forecast: 20,
        brokers: [],
        risk_level: 'high',
    },
    {
        id: 6,
        name: 'Biotech Sector Swing Trading',
        author: {
            username: 'BioTrader',
            profile_image: 'https://sneg.top/uploads/posts/2023-06/1687994769_sneg-top-p-avatarka-s-voprositelnim-znakom-krasivo-12.jpg',
        },
        image: 'https://dividendoff.net/wp-content/uploads/2019/04/2-4.jpg',
        forecast: 12,
        brokers: [],
        risk_level: 'medium',
    },
    {
        id: 7,
        name: 'Dividend Growth Investing',
        author: {
            username: 'DividendKing',
            profile_image: 'https://sneg.top/uploads/posts/2023-06/1687994769_sneg-top-p-avatarka-s-voprositelnim-znakom-krasivo-12.jpg',
        },
        image: 'https://dividendoff.net/wp-content/uploads/2019/04/2-4.jpg',
        forecast: 10,
        brokers: [],
        risk_level: 'low',
    },
    {
        id: 8,
        name: 'Dividend Growth Investing',
        author: {
            username: 'DividendKing',
            profile_image: 'https://sneg.top/uploads/posts/2023-06/1687994769_sneg-top-p-avatarka-s-voprositelnim-znakom-krasivo-12.jpg',
        },
        image: 'https://dividendoff.net/wp-content/uploads/2019/04/2-4.jpg',
        forecast: 10,
        brokers: [],
        risk_level: 'low',
    },
    {
        id: 9,
        name: 'Dividend Growth Investing',
        author: {
            username: 'DividendKing',
            profile_image: 'https://sneg.top/uploads/posts/2023-06/1687994769_sneg-top-p-avatarka-s-voprositelnim-znakom-krasivo-12.jpg',
        },
        image: 'https://dividendoff.net/wp-content/uploads/2019/04/2-4.jpg',
        forecast: 10,
        brokers: [],
        risk_level: 'low',
    },
    {
        id: 10,
        name: 'Dividend Growth Investing',
        author: {
            username: 'DividendKing',
            profile_image: 'https://sneg.top/uploads/posts/2023-06/1687994769_sneg-top-p-avatarka-s-voprositelnim-znakom-krasivo-12.jpg',
        },
        image: 'https://dividendoff.net/wp-content/uploads/2019/04/2-4.jpg',
        forecast: 10,
        brokers: [],
        risk_level: 'low',
    },
];
