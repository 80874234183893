import { styled } from '@/app/styles/mainStyles';
export var SearchWrapper = styled('div', {
    position: 'relative',
    width: '100%',
    border: '1px solid $controlsStrokeDefault',
    boxSizing: 'border-box',
    borderRadius: '16px',
    transition: 'all .2s',
    color: '$darkText',
    '&:hover:not([data-state-disabled="true"])': {
        border: '1px solid $controlsStrokeHover',
    },
    '&:focus-within:not([data-state-disabled="true"]), &:active:not([data-state-disabled="true"])': {
        border: '1px solid $controlsStrokeFocus',
    },
    '&[data-state-disabled="true"]': {
        border: 'none',
        color: '$textDisabled',
    },
    variants: {
        size: {
            big: {
                borderRadius: '16px',
            },
            small: {
                borderRadius: '9px',
                height: '36px',
            },
            default: {},
        },
        border: {
            true: {
                border: '1px solid $controlsStrokeDefault',
            },
            false: {
                border: '1px solid transparent',
            },
        },
    },
});
export var CrossIcon = styled('button', {
    position: 'absolute',
    display: 'inline-flex',
    cursor: 'pointer',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    borderRadius: '8px',
    padding: '8px',
    transition: 'background-color .2s',
    outline: 'none',
    border: 'none',
    background: 'none',
    color: '$primaryText',
    '&:hover': {
        backgroundColor: '$controlsBgSecondaryHover',
    },
    variants: {
        size: {
            big: {
                svg: {
                    width: 24,
                    height: 24,
                },
            },
            small: {
                padding: '4px',
                svg: {
                    width: 16,
                    height: 16,
                },
            },
            default: {},
        },
    },
});
export var SearchIcon = styled('div', {
    position: 'absolute',
    display: 'inline-flex',
    top: '50%',
    left: '10px',
    color: 'inherit',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
    variants: {
        size: {
            big: {
                svg: {
                    width: 24,
                    height: 24,
                },
            },
            small: {
                svg: {
                    width: 16,
                    height: 16,
                },
            },
            default: {},
        },
    },
});
export var SearchInput = styled('input', {
    width: '100%',
    boxSizing: 'border-box',
    color: '$primaryText',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
    '&::placeholder': {
        color: '$darkText',
    },
    '&[disabled]': {
        color: '$textDisabled',
        '&::placeholder': {
            color: '$textDisabled',
        },
    },
    variants: {
        color: {
            white: {
                backgroundColor: '$controlsBgDefault',
            },
            gray: {
                backgroundColor: '$bgInput',
            },
        },
        size: {
            big: {
                fontSize: '20px',
                fontWeight: '400',
                borderRadius: '16px',
                lineHeight: '28px',
                fontFamily: '$montserrat',
                padding: '20px 10px 20px 46px',
                height: '70px',
                '@mobile': {
                    height: '60px',
                },
            },
            small: {
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '22px',
                fontFamily: '$montserrat',
                height: '34px',
                borderRadius: '8px',
                padding: '0 10px 0 34px',
            },
            default: {},
        },
    },
    '@mobile': {
        fontSize: '16px',
        lineHeight: '24px',
        padding: '18px 10px 18px 46px',
    },
});
