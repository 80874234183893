var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as S from './style';
import { ReactComponent as Search } from '@/shared/assets/icons/SearchIcon.svg';
import { ReactComponent as Notification } from '@/shared/assets/icons/NotificationIcon.svg';
import React, { useState } from 'react';
import { AvatarWithName, Icon } from '@/shared/ui';
import { useHistory } from 'react-router-dom';
import { RoutePath } from '@/shared/config/routeConfig/routeConfig';
import { DropdownHeader } from '@/widgets/ui/Header/ui/DropdownHeader/DropdownHeader';
import { ThemeToggle } from '@/shared/ui/ThemeToggle/ThemeToggle';
import { useTypedSelector } from '@/shared/hooks/useTypesSelector/useTypesSelector';
import { SearchDialog } from '@/widgets/ui/Landing/SearchDialog/SearchDialog';
var HeaderProfile = function (_a) {
    var token = _a.token, userData = _a.userData;
    var history = useHistory();
    var isMobile = window.matchMedia('(max-width: 1279px)').matches;
    var currentTheme = useTypedSelector(function (state) { return state.themeReducer.theme; });
    var _b = useState(false), searchOpen = _b[0], setSearchOpen = _b[1];
    var handleLogIn = function () {
        sessionStorage.setItem('prevPath', window.location.pathname);
        history.push(RoutePath.login);
    };
    return (_jsxs(S.HeaderProfileContainer, { children: [token ?
                _jsxs(_Fragment, { children: [!isMobile && _jsxs(_Fragment, { children: [_jsx(ThemeToggle, {}), _jsxs("div", __assign({ style: { display: 'flex', gap: '4px' } }, { children: [_jsx("div", __assign({ onClick: function () { return setSearchOpen(true); }, style: {
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '40px',
                                                height: '40px',
                                                cursor: 'pointer',
                                            } }, { children: _jsx(Icon, { css: {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end',
                                                    height: '20px',
                                                    fill: '$primaryText',
                                                }, IconItem: Search, fill: currentTheme === 'dark' ? 'white' : 'black' }) })), _jsx("div", __assign({ style: {
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '40px',
                                                height: '40px',
                                                cursor: 'pointer',
                                            } }, { children: _jsx(Icon, { IconItem: Notification, css: { fill: '$primaryText', display: 'flex', alignItems: 'center' }, fill: currentTheme === 'dark' ? 'white' : 'black' }) }))] }))] }), _jsx(S.AvatarWrapper, { children: !isMobile ? (_jsx(DropdownHeader, { trigger: _jsx(AvatarWithName, { userData: userData }) })) : (_jsx(AvatarWithName, { userData: userData })) })] })
                :
                    _jsxs(_Fragment, { children: [!isMobile &&
                                _jsxs(_Fragment, { children: [_jsx(ThemeToggle, {}), _jsx("div", __assign({ onClick: function () { return setSearchOpen(true); }, style: { display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px' } }, { children: _jsx(Icon, { css: {
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    height: '20px',
                                                    fill: '$primaryText',
                                                    cursor: 'pointer',
                                                }, IconItem: Search, fill: currentTheme === 'dark' ? 'white' : 'black' }) }))] }), _jsx(S.StyledButton, __assign({ onClick: handleLogIn }, { children: "Log in" }))] }), _jsx(SearchDialog, { open: searchOpen, close: function () { return setSearchOpen(false); } })] }));
};
export { HeaderProfile };
