import { styled } from '@stitches/react';
import { ReactComponent as Loading } from '@/shared/assets/icons/loading.svg';
import { keyframes } from '@stitches/react';
export var HeaderWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
});
export var TableHeader = styled('div', {
    display: 'flex',
    gap: '40px',
    alignItems: 'center',
    padding: '22px 18px 14px 18px',
    justifyContent: 'space-between',
    borderBottom: '1px solid $divider',
});
export var SourceButton = styled('div', {
    display: 'flex',
    height: '36px',
    alignItems: 'center',
    padding: '10px 9px',
    gap: '8px',
    borderRadius: '8px',
    cursor: 'pointer',
    boxSizing: 'border-box',
    transition: 'all 0.2s ease',
    maxWidth: '300px',
    width: 'fit-content',
    '&:hover': {
        background: '$controlsBgGhostAccentHover',
    },
});
export var Chevron = styled('div', {
    display: 'flex',
    alignItems: 'center',
    width: '18px',
    height: '16px',
});
export var Row = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: '6px 20px',
    gap: '40px',
    borderRadius: '12px',
    position: 'relative',
    '&::after': {
        content: '',
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        right: '0px',
        height: '1px',
        backgroundColor: '$divider',
    },
    '&:hover': {
        background: '$controlsBgSecondaryDefault',
    },
});
export var Logo = styled('img', {
    borderRadius: '100%',
    objectFit: 'cover',
    objectPosition: '50% 50%',
});
export var LinkWrapper = styled('div', {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    gap: '48px',
    cursor: 'pointer',
});
export var LoadingContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    height: '100%',
});
var rotateAnimation = keyframes({
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
});
export var LoadingIcon = styled(Loading, {
    animation: "".concat(rotateAnimation, " 1s linear infinite"),
});
export var LoadingIconContainer = styled('div', {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
export var TickersContainer = styled('div', {
    boxSizing: 'border-box',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
        width: '16px',
        background: '$bgSecondlayer',
    },
    '&::-webkit-scrollbar-thumb': {
        border: '3px solid $bgSecondlayer',
        background: '$controlsBgSecondaryDefault',
        borderRadius: '8px',
    },
});
export var NoFoundContainer = styled('div', {
    boxSizing: 'border-box',
    flexDirection: 'column',
    gap: '4px',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
export var NoFoundText = styled('div', {
    boxSizing: 'border-box',
    color: '$primaryText',
    fontSize: '20px',
});
export var ExchangeWrapper = styled('div', {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
    gap: '8px',
    padding: '8px 16px',
    '&:hover': {
        background: '$controlsBgSecondaryDefault',
        cursor: 'pointer',
    },
});
export var ExchangeButton = styled('div', {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
});
