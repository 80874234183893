var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Text } from '@/shared/ui';
import * as S from './style';
import { ReactComponent as Warning } from '@/shared/assets/icons/Warning.svg';
import { ReactComponent as Attention } from '@/shared/assets/icons/Attention.svg';
import { useTypedSelector } from '@/shared/hooks/useTypesSelector/useTypesSelector';
var Informer = function (props) {
    var _a = props.type, type = _a === void 0 ? 'attention' : _a, children = props.children, buttonText = props.buttonText, alignIcon = props.alignIcon, css = props.css;
    var currentTheme = useTypedSelector(function (state) { return state.themeReducer.theme; });
    var getTextColor = function (InformerType) {
        switch (InformerType) {
            case 'attention':
                return '$primaryText';
            case 'warning':
                return '$primaryText';
            case 'info':
                return '$primaryText';
        }
    };
    return (_jsxs(S.InformerWrapper, __assign({ css: css, type: type, alignIcon: alignIcon }, { children: [type === 'info' &&
                _jsx(Warning, { color: currentTheme === 'dark' ? '#FFF' : '#1D2023' }), type === 'attention' &&
                _jsx(Warning, { color: currentTheme === 'dark' ? '#FFF' : '#1D2023' }), type === 'warning' &&
                _jsx(Attention, { fill: currentTheme === 'dark' ? '#FFF' : '#1D2023' }), _jsx(Text, __assign({ type: "header", css: {
                    width: '100%',
                    color: getTextColor(type),
                } }, { children: _jsxs(S.ContentWrapper, { children: [children, buttonText && (_jsx(S.InformerButton, { children: buttonText }))] }) }))] })));
};
export { Informer };
