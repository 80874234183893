import { styled } from '@/app/styles/mainStyles';
import { Text } from '@/shared/ui/Text/style';
export var LabelContainer = styled('div', {
    borderRadius: '8px',
    padding: '4px 8px',
    variants: {
        type: {
            draft: {
                background: '$controlsBgSecondaryDefault',
            },
            pub: {
                background: '$purpleBgDefault',
            },
            local: {
                background: '$blueBgDefault',
            },
        },
    },
});
export var LabelText = styled(Text, {
    variants: {
        color: {
            draft: {
                color: '$primaryText',
            },
            pub: {
                color: '$purpleText',
            },
            local: {
                color: '$blueText',
            },
        },
    },
});
