import { styled } from '@/app/styles/mainStyles';
import { Button } from '@/shared/ui/Button/style';
export var HeaderProfileContainer = styled('div', {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    alignItems: 'center',
    position: 'relative',
    gap: '8px',
});
export var StyledButton = styled(Button, {
    background: 'transparent',
    color: '$primaryText',
    fontSize: '20px',
    height: '52px',
    transition: 'background 0.2s',
    '@mobile': {
        width: '76px',
        height: '44px',
        fontSize: '14px',
        fontWeight: 500,
        padding: '12px 16px !important',
        color: '$primaryText',
        '&:hover': {
            background: 'transparent',
        },
    },
    '&:hover': {
        background: '$controlsBgSecondaryHover',
    },
});
export var CloseSettings = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '36px',
    height: '36px',
});
export var AvatarWithNameContainer = styled('div', {});
export var AvatarWrapper = styled('div', {
    marginLeft: '12px',
    '@mobile': {
        marginLeft: '27px',
    },
});
