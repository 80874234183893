var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_FETCH_STATUS } from '@/shared/api/constants/baseFetch.status';
import { START_FETCH_STATUS } from '@/shared/api/constants/startFetch.status';
import { typeMapping } from './const';
var initialState = {
    strategyBlockResult: null,
    strategyBlockStatus: BASE_FETCH_STATUS,
    taskIdResult: null,
    taskIdStatus: BASE_FETCH_STATUS,
    runResult: null,
    runStatus: BASE_FETCH_STATUS,
    backtestData: null,
    backtestStrategyStatus: BASE_FETCH_STATUS,
    isShowData: true,
    tickersInfoResult: null,
    tickersInfoStatus: BASE_FETCH_STATUS,
    typeSingleBlockResult: null,
    typeSingleBlockStatus: BASE_FETCH_STATUS,
    blocksIds: null,
    typeBlocksResult: null,
    typeBlocksStatus: BASE_FETCH_STATUS,
    blocksInfo: null,
};
var getTickersInfo = createAsyncThunk('backtest/getTickersInfo', function (_a, thunkAPI) {
    var ticker_ids = _a.ticker_ids;
    return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, thunkAPI.extra.api.post('/tickers/tickers_details/', { ticker_ids: ticker_ids })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _b.sent();
                    return [2 /*return*/, thunkAPI.rejectWithValue(error_1)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
var getBacktestData = createAsyncThunk('backtest/getBacktestData', function (_a, thunkAPI) {
    var task_id = _a.task_id;
    return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, thunkAPI.extra.api.post('/strategy-workspace/run/get_result/', { task_id: task_id })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_2 = _b.sent();
                    return [2 /*return*/, thunkAPI.rejectWithValue(error_2)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
var getTaskId = createAsyncThunk('backtestSlice/getTaskId', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.post("/strategy-workspace/".concat(payload.id, "/run/backtest/"), payload.data)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_1 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_1)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getTypeSingleBlock = createAsyncThunk('backtest/getTypeSingleBlock', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, typeBLock, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/strategy-block/".concat(payload.id, "/"))];
            case 1:
                resp = _a.sent();
                typeBLock = typeMapping[resp.data.strategy_step_type];
                return [2 /*return*/, typeBLock];
            case 2:
                e_2 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_2)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getTypeBlocks = createAsyncThunk('backtest/getTypeBlocks', function (ids, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var results, resultObject, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Promise.all(ids.map(function (id) { return __awaiter(void 0, void 0, void 0, function () {
                        var response, typeBlock;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, thunkAPI.extra.api.get("/strategy-block/".concat(id, "/"))];
                                case 1:
                                    response = _a.sent();
                                    typeBlock = typeMapping[response.data.strategy_step_type];
                                    return [2 /*return*/, { id: id, typeBlock: typeBlock }];
                            }
                        });
                    }); }))];
            case 1:
                results = _a.sent();
                resultObject = results.reduce(function (acc, _a) {
                    var id = _a.id, typeBlock = _a.typeBlock;
                    acc[id] = { type_block: typeBlock };
                    return acc;
                }, {});
                return [2 /*return*/, resultObject];
            case 2:
                e_3 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_3)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getStrategyBlockData = createAsyncThunk('backtest/getStrategyBlockData', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/strategy-block/".concat(payload.id, "/"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_4 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_4)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var pollTaskStatus = createAsyncThunk('backtestSlice/pollTaskStatus', function (_a, thunkAPI) {
    var task_id = _a.task_id;
    return __awaiter(void 0, void 0, void 0, function () {
        var status, isShowData, response, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    status = 'RUNNING';
                    _b.label = 1;
                case 1:
                    if (!(status !== 'SUCCESS')) return [3 /*break*/, 4];
                    isShowData = thunkAPI.getState().backtestReducer.isShowData;
                    if (!isShowData) {
                        return [2 /*return*/, thunkAPI.rejectWithValue('Показ данных остановлен')];
                    }
                    return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 2000); })];
                case 2:
                    _b.sent();
                    return [4 /*yield*/, thunkAPI.extra.api.post('/strategy-workspace/run/get_result/', { task_id: task_id })];
                case 3:
                    response = _b.sent();
                    data = response.data;
                    status = data.task_status;
                    if (status === 'FAILURE') {
                        return [2 /*return*/, thunkAPI.rejectWithValue('Ошибка подсчета данных')];
                    }
                    if (status === 'The strategy has already been run') {
                        return [2 /*return*/, thunkAPI.rejectWithValue('The strategy has already been run')];
                    }
                    if (status === 'SUCCESS') {
                        return [2 /*return*/, data];
                    }
                    return [3 /*break*/, 1];
                case 4: return [2 /*return*/];
            }
        });
    });
});
var backtestStrategy = createAsyncThunk('backtestSlice/backtestStrategy', function (_a, thunkAPI) {
    var blockId = _a.blockId, strategyId = _a.strategyId, periodStart = _a.periodStart, periodFinish = _a.periodFinish;
    return __awaiter(void 0, void 0, void 0, function () {
        var taskResponse, response, data, state_data, branch_blocks, error_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, thunkAPI.dispatch(getTaskId({ id: strategyId, data: { block_pk: blockId, period_start: periodStart, period_finish: periodFinish } })).unwrap()];
                case 1:
                    taskResponse = _b.sent();
                    return [4 /*yield*/, thunkAPI.dispatch(pollTaskStatus({ task_id: taskResponse.task_id })).unwrap()];
                case 2:
                    response = _b.sent();
                    data = response.data, state_data = response.state_data, branch_blocks = response.branch_blocks;
                    // const { data, state_data, branch_blocks } = fakeData;
                    return [2 /*return*/, { data: data, state_data: state_data, branch_blocks: branch_blocks }];
                case 3:
                    error_3 = _b.sent();
                    return [2 /*return*/, thunkAPI.rejectWithValue(error_3)];
                case 4: return [2 /*return*/];
            }
        });
    });
});
var backtestSlice = createSlice({
    name: 'backtestSlice',
    initialState: initialState,
    reducers: {
        toggleShowData: function (state, _a) {
            var payload = _a.payload;
            state.isShowData = payload;
        },
        saveBlocksIds: function (state, _a) {
            var payload = _a.payload;
            state.blocksIds = payload;
        },
        saveBlocksInfo: function (state, _a) {
            var payload = _a.payload;
            state.blocksInfo = payload;
        },
        clearBacktestStrategyData: function (state) {
            state.strategyBlockResult = null;
            state.strategyBlockStatus = BASE_FETCH_STATUS;
            state.taskIdResult = null;
            state.taskIdStatus = BASE_FETCH_STATUS;
            state.runResult = null;
            state.runStatus = BASE_FETCH_STATUS;
            state.backtestData = null;
            state.backtestStrategyStatus = BASE_FETCH_STATUS;
            state.isShowData = false;
            state.tickersInfoResult = null;
            state.tickersInfoStatus = BASE_FETCH_STATUS;
            state.typeSingleBlockResult = null;
            state.typeSingleBlockStatus = BASE_FETCH_STATUS;
            state.blocksIds = null;
            state.typeBlocksResult = null;
            state.typeBlocksStatus = BASE_FETCH_STATUS;
            state.blocksInfo = null;
        },
    },
    extraReducers: function (builder) {
        builder.addCase(getTaskId.pending, function (state) {
            state.taskIdStatus = START_FETCH_STATUS;
        });
        builder.addCase(getTaskId.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.taskIdResult = payload;
            state.taskIdStatus.isLoading = false;
        });
        builder.addCase(getTaskId.rejected, function (state, _a) {
            var payload = _a.payload;
            state.taskIdStatus.error = payload;
            state.taskIdStatus.isLoading = false;
        });
        builder.addCase(getBacktestData.pending, function (state) {
            state.runStatus = START_FETCH_STATUS;
        });
        builder.addCase(getBacktestData.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.runResult = payload;
            state.runStatus.isLoading = false;
        });
        builder.addCase(getBacktestData.rejected, function (state, _a) {
            var payload = _a.payload;
            state.runStatus.error = payload;
            state.runStatus.isLoading = false;
        });
        builder.addCase(getStrategyBlockData.pending, function (state) {
            state.strategyBlockStatus = START_FETCH_STATUS;
        });
        builder.addCase(getStrategyBlockData.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.strategyBlockResult = payload;
            state.strategyBlockStatus.isLoading = false;
        });
        builder.addCase(getStrategyBlockData.rejected, function (state, _a) {
            var payload = _a.payload;
            state.strategyBlockStatus.error = payload;
            state.strategyBlockStatus.isLoading = false;
        });
        builder.addCase(backtestStrategy.pending, function (state) {
            state.backtestStrategyStatus = START_FETCH_STATUS;
            state.backtestData = null;
        });
        builder.addCase(backtestStrategy.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.backtestData = payload;
            state.backtestStrategyStatus.isLoading = false;
        });
        builder.addCase(backtestStrategy.rejected, function (state, _a) {
            var payload = _a.payload;
            state.backtestStrategyStatus.error = payload;
            state.backtestStrategyStatus.isLoading = false;
            state.backtestData = null;
        });
        builder.addCase(getTickersInfo.pending, function (state) {
            state.tickersInfoStatus = START_FETCH_STATUS;
        });
        builder.addCase(getTickersInfo.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.tickersInfoResult = payload;
            state.tickersInfoStatus.isLoading = false;
        });
        builder.addCase(getTickersInfo.rejected, function (state, _a) {
            var payload = _a.payload;
            state.tickersInfoStatus.error = payload;
            state.tickersInfoStatus.isLoading = false;
        });
        builder.addCase(getTypeSingleBlock.pending, function (state) {
            state.typeSingleBlockStatus = START_FETCH_STATUS;
        });
        builder.addCase(getTypeSingleBlock.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.typeSingleBlockResult = payload;
            state.typeSingleBlockStatus.isLoading = false;
        });
        builder.addCase(getTypeSingleBlock.rejected, function (state, _a) {
            var payload = _a.payload;
            state.typeSingleBlockStatus.error = payload;
            state.typeSingleBlockStatus.isLoading = false;
        });
        builder.addCase(getTypeBlocks.pending, function (state) {
            state.typeBlocksStatus = START_FETCH_STATUS;
        });
        builder.addCase(getTypeBlocks.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.typeBlocksResult = payload;
            state.typeBlocksStatus.isLoading = false;
        });
        builder.addCase(getTypeBlocks.rejected, function (state, _a) {
            var payload = _a.payload;
            state.typeBlocksStatus.error = payload;
            state.typeBlocksStatus.isLoading = false;
        });
    },
});
export var clearBacktestStrategyData = (_a = backtestSlice.actions, _a.clearBacktestStrategyData), toggleShowData = _a.toggleShowData, saveBlocksIds = _a.saveBlocksIds, saveBlocksInfo = _a.saveBlocksInfo;
export default backtestSlice.reducer;
export { getTaskId, getTypeSingleBlock, getStrategyBlockData, backtestStrategy, getBacktestData, getTickersInfo, getTypeBlocks };
