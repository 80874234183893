import { styled } from '@/app/styles/mainStyles';
export var AvatarWithNameContainer = styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
});
export var ImgContainer = styled('img', {
    borderRadius: '20px',
    width: '24px',
    height: '24px',
    objectFit: 'cover',
    '@mobile': {
        width: '36px',
        height: '36px',
    },
});
