export var tabsData = [
    {
        id: 'all',
        value: 'All',
    },
    {
        id: 'stocks',
        value: 'Stocks',
    },
    {
        id: 'crypto',
        value: 'Crypto',
    },
    {
        id: 'currencies',
        value: 'Currencies',
    },
    {
        id: 'futures',
        value: 'Futures',
    },
];
