import { Content, Root, Trigger, Arrow, Portal } from '@radix-ui/react-popover';
import { keyframes } from '@stitches/react';
import { styled } from '@/app/styles/mainStyles';
var slideUpAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});
var slideRightAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(-2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});
var slideDownAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});
var slideLeftAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});
var StyledRoot = styled(Root, {
    zIndex: 9999999999,
});
var PopoverContent = styled(Content, {
    color: '$primaryText',
    borderRadius: 8,
    fontFamily: '$montserrat',
    padding: '8px',
    maxWidth: '220px',
    zIndex: 99999999,
    width: 'fit-content',
    border: 'none',
    outline: 'none',
    boxSizing: 'border-box',
    background: '$bgSecondlayer',
    boxShadow: '$modalsShadow',
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
        '&[data-side="top"]': { animationName: slideDownAndFade },
        '&[data-side="right"]': { animationName: slideLeftAndFade },
        '&[data-side="bottom"]': { animationName: slideUpAndFade },
        '&[data-side="left"]': { animationName: slideRightAndFade },
    },
});
var PopoverArrow = styled(Arrow, {
    fill: 'white',
});
export { StyledRoot, Trigger, PopoverContent, PopoverArrow, Portal };
