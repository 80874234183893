import { styled } from '@/app/styles/mainStyles';
import { keyframes } from '@stitches/react';
var rotateAnimation = keyframes({
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
});
export var LoadingIconContainer = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    width: '100%',
    height: '100%',
    transformOrigin: 'center center',
    animation: "".concat(rotateAnimation, " 1s linear infinite"),
});
