var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, Search, Tabs, Text, Space, Popover } from '@/shared/ui';
import { useState, useEffect } from 'react';
import { ReactComponent as Planet } from '@/shared/assets/icons/Planet.svg';
import { ReactComponent as Chevron } from '@/shared/assets/icons/Arrow.svg';
import { ReactComponent as NoData } from '@/shared/assets/icons/Nodata.svg';
import { ReactComponent as NoDataDark } from '@/shared/assets/icons/NodataDark.svg';
import { LineChart, Line, YAxis } from 'recharts';
import * as S from './style';
import { useHistory } from 'react-router-dom';
import { tabsData } from '@/widgets/ui/Landing/SearchDialog/const';
import { getCompanyDataTickers } from '@/entities/companyData/model/companyDataSlice';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { useTypedSelector } from '@/shared/hooks/useTypesSelector/useTypesSelector';
var SearchDialog = function (_a) {
    var open = _a.open, close = _a.close, onSelectTicker = _a.onSelectTicker, _b = _a.type, type = _b === void 0 ? 'default' : _b;
    var history = useHistory();
    var _c = useState('all'), activeTab = _c[0], setActiveTab = _c[1];
    var _d = useState(''), searchQuery = _d[0], setSearchQuery = _d[1];
    var _e = useState(false), allSourceClicked = _e[0], setAllSourceClicked = _e[1];
    var _f = useState('All sources'), selectedExchange = _f[0], setSelectedExchange = _f[1];
    var dispatch = useTypedDispatch();
    var currentTheme = useTypedSelector(function (state) { return state.themeReducer.theme; });
    var companyDataTickers = useTypedSelector(function (state) { return state.companyDataReducer.companyDataTickers; });
    var companyDataTickersStatus = useTypedSelector(function (state) { return state.companyDataReducer.companyDataTickersStatus; });
    var handleClickTab = function (tab) {
        setActiveTab(tab.id);
    };
    var handleRowClick = function (path) {
        if (type === 'widget') {
            onSelectTicker(path);
        }
        else {
            window.open("/ticker/".concat(path.name), '_blank');
        }
    };
    var getChartData = function (priceData) {
        return priceData.map(function (price, index) { return ({ price: price, index: index }); });
    };
    var getLineColor = function (priceData) {
        return priceData[0] > priceData[priceData.length - 1] ? '#D32E5F' : '#4CAF50';
    };
    var closeDialog = function () {
        setActiveTab('all');
        close();
    };
    useEffect(function () {
        var asset_type;
        var ticker_type;
        var crypto;
        switch (activeTab) {
            case 'stocks':
                asset_type = 0;
                ticker_type = 0;
                break;
            case 'crypto':
                asset_type = 2;
                ticker_type = 0;
                break;
            case 'futures':
                ticker_type = 1;
                break;
            case 'currencies':
                asset_type = 1;
                ticker_type = 0;
                break;
            default:
                break;
        }
        dispatch(getCompanyDataTickers({
            limit: 50,
            offset: 0,
            asset_type: asset_type,
            ticker_type: ticker_type,
            crypto: crypto,
        }));
    }, [activeTab]);
    var filteredTickers = companyDataTickers === null || companyDataTickers === void 0 ? void 0 : companyDataTickers.filter(function (ticker) {
        var matchesSearchQuery = ticker.name.toLowerCase().includes(searchQuery.toLowerCase());
        var matchesExchange = selectedExchange === 'All sources' || ticker.exchange_name === selectedExchange;
        return matchesSearchQuery && matchesExchange;
    });
    var uniqueExchange = companyDataTickers === null || companyDataTickers === void 0 ? void 0 : companyDataTickers.filter(function (ticker, index, self) {
        return index === self.findIndex(function (t) { return t.exchange_name === ticker.exchange_name; });
    });
    return (_jsxs(Dialog, __assign({ css: {
            width: '1092px',
            height: '820px',
            zIndex: '99999999',
            paddingBottom: '0px',
            background: '$bgSecondlayer',
        }, disableAutofocus: true, open: open, onOpenChange: function () {
            closeDialog();
            setAllSourceClicked(false);
        }, outside: false, disableHeader: true }, { children: [_jsxs(S.HeaderWrapper, { children: [_jsx(Search, { value: searchQuery, onChange: function (e) { return setSearchQuery(e.target.value); }, onClear: function () { return setSearchQuery(''); } }), _jsx(Tabs, { active: activeTab, onClick: function (t) {
                            handleClickTab(t);
                            setSelectedExchange('All sources');
                        }, tabs: tabsData, type: "chips", styleType: "chips" })] }), _jsxs(S.TableHeader, { children: [_jsxs(Space, { children: [_jsx(Text, __assign({ type: "header", css: { color: '$textTertiaryDisabled', width: '210px' } }, { children: "SYMBOL" })), _jsx(Text, __assign({ type: "header", css: { color: '$textTertiaryDisabled', width: '110px' } }, { children: "1M PERF." })), _jsx(Text, __assign({ type: "header", css: { color: '$textTertiaryDisabled', width: 'fit-content' } }, { children: "DESCRIPTION" }))] }), _jsxs(Popover, __assign({ open: allSourceClicked, side: 'bottom', css: { width: 'fit-content' }, onChangeOpen: function () {
                            if (uniqueExchange) {
                                setAllSourceClicked(!allSourceClicked);
                            }
                        }, trigger: _jsxs(S.SourceButton, { children: [_jsxs(Space, __assign({ size: 'default', align: 'center' }, { children: [_jsx(Planet, { width: 16, height: 16, color: '#00A5BC' }), _jsx(Text, __assign({ type: "header", css: { color: '$aquamarine', width: 'max-content' } }, { children: selectedExchange }))] })), _jsx(S.Chevron, { children: _jsx(Chevron, { fill: "#00A5BC" }) })] }) }, { children: [_jsx(S.ExchangeWrapper, __assign({ onClick: function () {
                                    setSelectedExchange('All sources');
                                    setAllSourceClicked(false);
                                } }, { children: _jsx(S.ExchangeButton, { children: "All sources" }) })), uniqueExchange === null || uniqueExchange === void 0 ? void 0 : uniqueExchange.map(function (exchange) { return (_jsxs(S.ExchangeWrapper, __assign({ onClick: function () {
                                    setSelectedExchange(exchange.exchange_name);
                                    setAllSourceClicked(false);
                                } }, { children: [_jsx(S.Logo, { src: exchange.exchange_image_url ? "https://".concat(exchange.exchange_image_url) : 'https://101ways.ru/russia/2016/img/2/6021.jpg', css: { width: '24px', height: '24px' } }), _jsx(S.ExchangeButton, { children: exchange.exchange_name })] }))); })] }))] }), companyDataTickersStatus.isLoading ? (_jsx(S.LoadingContainer, { children: _jsx(S.LoadingIconContainer, { children: _jsx(S.LoadingIcon, {}) }) })) : filteredTickers && filteredTickers.length > 0 ? (_jsx(S.TickersContainer, { children: filteredTickers.map(function (ticker) { return (_jsxs(S.Row, { children: [_jsxs(S.LinkWrapper, __assign({ onClick: function () { return handleRowClick(ticker); } }, { children: [_jsxs(Space, __assign({ size: "medium", align: "center", css: { width: '160px' } }, { children: [_jsx(S.Logo, { src: ticker.logo_url ? "https://".concat(ticker.logo_url) : 'https://101ways.ru/russia/2016/img/2/6021.jpg', css: { width: '32px', height: '32px' } }), _jsx(Text, __assign({ type: "primary", css: { fontWeight: 400 } }, { children: ticker.name }))] })), _jsx("div", __assign({ style: {
                                        display: 'flex',
                                        width: '60px',
                                        height: '60px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    } }, { children: _jsxs(LineChart, __assign({ width: 60, height: 60, data: getChartData(ticker.prices_data) }, { children: [_jsx(YAxis, { domain: ['dataMin - 1000', 'dataMax + 1000'], hide: true }), _jsx(Line, { dataKey: "price", stroke: getLineColor(ticker.prices_data), dot: false, isAnimationActive: false })] })) })), _jsx(Text, __assign({ type: "primary", css: {
                                        width: '500px',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        fontWeight: 400,
                                    } }, { children: ticker.description }))] })), _jsxs(Space, __assign({ size: "medium", align: "center" }, { children: [_jsx(Text, __assign({ type: "primary", css: { fontWeight: 400 } }, { children: ticker.exchange_name })), _jsx(S.Logo, { src: ticker.exchange_image_url ? "https://".concat(ticker.exchange_image_url) : 'https://101ways.ru/russia/2016/img/2/6021.jpg', css: { width: '32px', height: '32px' } })] }))] }, ticker.name)); }) })) : (_jsxs(S.NoFoundContainer, { children: [currentTheme === 'dark' ? _jsx(NoDataDark, {}) : _jsx(NoData, {}), _jsx(S.NoFoundText, { children: "No results found" })] }))] })));
};
export { SearchDialog };
