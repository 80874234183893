var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import { useState, useEffect } from 'react';
import { useTypedSelector } from '@/shared/hooks/useTypesSelector/useTypesSelector';
var Popover = function (_a) {
    var children = _a.children, onChangeOpen = _a.onChangeOpen, open = _a.open, trigger = _a.trigger, css = _a.css, _b = _a.side, side = _b === void 0 ? 'bottom' : _b;
    var currentTheme = useTypedSelector(function (state) { return state.themeReducer.theme; });
    var _c = useState(null), modalContainer = _c[0], setModalContainer = _c[1];
    useEffect(function () {
        var mainContainer = document.querySelector('.main-container');
        setModalContainer(mainContainer);
    }, []);
    return (_jsxs(S.StyledRoot, __assign({ open: open, onOpenChange: onChangeOpen }, { children: [_jsx(S.Trigger, __assign({ onClick: function (event) { return event.stopPropagation(); }, asChild: true }, { children: trigger })), _jsx(S.Portal, __assign({ container: modalContainer }, { children: _jsx(S.PopoverContent, __assign({ style: {
                        background: currentTheme === 'dark' ? '#323A46' : '#FFFFFF', color: currentTheme === 'dark' ? '#FFFFFF' : '#323A46',
                        fill: currentTheme === 'dark' ? '#FFFFFF' : '#323A46',
                    }, align: "end", sideOffset: 6, side: side, css: css }, { children: children })) }))] })));
};
export { Popover };
