var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import { Text } from '@/shared/ui/Text/Text';
import { BASE_URL } from '@/shared/api/api';
import { EmptyAvatar } from '@/shared/ui/EmptyAvatar/EmptyAvatar';
var AvatarWithName = function (_a) {
    var userData = _a.userData;
    var isMobile = window.matchMedia('(max-width: 1279px)').matches;
    var avatarPath = (userData === null || userData === void 0 ? void 0 : userData.profile_image) ? new URL(userData.profile_image, BASE_URL).href + "?t=".concat(new Date().getTime()) : '';
    return (_jsxs(S.AvatarWithNameContainer, { children: [(userData === null || userData === void 0 ? void 0 : userData.profile_image) ? (_jsx(S.ImgContainer, { src: avatarPath })) : (_jsx(EmptyAvatar, { firstName: userData === null || userData === void 0 ? void 0 : userData.username, size: isMobile ? 36 : 24 })), !isMobile && _jsx(Text, __assign({ type: "header" }, { children: userData === null || userData === void 0 ? void 0 : userData.username }))] }));
};
export { AvatarWithName };
