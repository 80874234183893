import { RoutePath } from '@/shared/config/routeConfig/routeConfig';
import { ReactComponent as News } from '@/shared/assets/icons/News.svg';
import { ReactComponent as Risk } from '@/shared/assets/icons/Risk.svg';
import { ReactComponent as Workspace } from '@/shared/assets/icons/grid.svg';
import { ReactComponent as Robot } from '@/shared/assets/icons/robot.svg';
export var navItems = [
    {
        id: 'strategies',
        text: 'Strategies',
        route: RoutePath.strategies,
    },
    {
        id: 'workspace',
        text: 'Workspace',
        route: RoutePath.workspace,
    },
    {
        id: 'news',
        text: 'News',
        route: RoutePath.news,
        children: [
            {
                id: 'newsTrends',
                text: 'Trends',
                icon: Risk,
                route: RoutePath.newsTrends,
            },
            {
                id: 'news',
                text: 'News Flow',
                icon: News,
                route: RoutePath.news,
            },
        ],
    },
    {
        id: 'plans',
        text: 'Pricing',
        route: RoutePath.plans,
    },
    {
        id: 'partnership',
        text: 'Partnership',
        route: RoutePath.partnership,
    },
];
export var navItemsWithoutAuth = [
    {
        id: 'strategies',
        text: 'Strategies',
        route: RoutePath.strategies,
    },
    {
        id: 'workspacePreview',
        text: 'Workspace',
        route: RoutePath.workspacePreview,
    },
    {
        id: 'news',
        text: 'News',
        route: RoutePath.news,
        children: [
            {
                id: 'newsTrends',
                text: 'Trends',
                icon: Risk,
                route: RoutePath.newsTrends,
            },
            {
                id: 'news',
                text: 'News Flow',
                icon: News,
                route: RoutePath.news,
            },
        ],
    },
    {
        id: 'plans',
        text: 'Pricing',
        route: RoutePath.plans,
    },
    {
        id: 'partnership',
        text: 'Partnership',
        route: RoutePath.partnership,
    },
];
export var mobileNav = [
    {
        items: [
            {
                id: 'strategies',
                text: 'Strategies',
                icon: Robot,
                route: RoutePath.strategies,
            },
            {
                id: 'workspace',
                text: 'Workspace',
                icon: Workspace,
                route: RoutePath.workspace,
            },
            {
                id: 'newsFlow',
                text: 'News flow',
                icon: News,
                route: RoutePath.news,
            },
            {
                id: 'trends',
                text: 'Trends',
                icon: Risk,
                route: RoutePath.newsTrends,
            },
            {
                id: 'plans',
                text: 'Plans',
                route: RoutePath.plans,
            },
            {
                id: 'partnership',
                text: 'Partnership',
                route: RoutePath.partnership,
            },
        ],
    },
];
export var mobileNavWithoutAuth = [
    {
        items: [
            {
                id: 'strategies',
                text: 'Strategies',
                icon: Robot,
                route: RoutePath.strategies,
            },
            {
                id: 'workspace',
                text: 'Workspace',
                icon: Workspace,
                route: RoutePath.workspacePreview,
            },
            {
                id: 'newsFlow',
                text: 'News flow',
                icon: News,
                route: RoutePath.news,
            },
            {
                id: 'trends',
                text: 'Trends',
                icon: Risk,
                route: RoutePath.newsTrends,
            },
        ],
    },
    {
        title: '',
        items: [
            {
                id: 'plans',
                text: 'Plans',
                route: RoutePath.plans,
            },
            {
                id: 'partnership',
                text: 'Partnership',
                route: RoutePath.partnership,
            },
        ],
    },
];
export var mobileSettings = [
    {
        title: 'Settings',
        items: [
            {
                id: 'personalInfo',
                text: 'Personal info',
                route: RoutePath.settings,
            },
            {
                id: 'password',
                text: 'Password',
                route: RoutePath.settings,
            },
            {
                id: 'keyManagement',
                text: 'Key management',
                route: RoutePath.settings,
            },
        ],
    },
    {
        title: '',
        items: [
            {
                id: 'helpCenter',
                text: 'Help Center',
                route: RoutePath.settings,
            },
        ],
    },
];
export var data = [
    [
        {
            id: 'Strategies',
            title: 'Strategies',
            path: RoutePath.strategies,
        },
        {
            id: 'Workspace',
            title: 'Workspace',
            path: RoutePath.workspace,
        },
        {
            id: 'News',
            title: 'News',
            path: RoutePath.news,
        },
        {
            id: 'Trends',
            title: 'Trends',
            path: RoutePath.newsTrends,
        },
        {
            id: 'Pricing',
            title: 'Pricing',
            path: RoutePath.plans,
        },
        {
            id: 'Partnership',
            title: 'Partnership',
            path: RoutePath.partnership,
        },
    ],
    [
        {
            id: 'HelpCenter',
            title: 'Help Center',
            path: '/',
        },
        {
            id: 'API',
            title: 'API',
            path: '/',
        },
        {
            id: 'PrivacyPolicy',
            title: 'Privacy Policy',
            path: '/',
        },
        {
            id: 'Terms & Conditions',
            title: 'Terms & Conditions',
            path: '/',
        },
    ],
];
