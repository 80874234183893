var _a, _b;
import { RoutePath } from '@/shared/config/routeConfig/routeConfig';
export var ignoreHeaderList = (_a = {},
    _a[RoutePath.register] = true,
    _a[RoutePath.createWorkspace] = true,
    _a[RoutePath.login] = true,
    _a[RoutePath.reset_password] = true,
    _a['/createWorkspace'] = true,
    _a[RoutePath.new_password] = true,
    _a[RoutePath.alternativelanding] = true,
    _a);
export var ignoreFooterList = (_b = {},
    _b[RoutePath.register] = { isAuthZone: false },
    _b[RoutePath.login] = { isAuthZone: false },
    // [RoutePath.strategies]: { isAuthZone: true },
    // [RoutePath.news]: { isAuthZone: false },
    _b['/createWorkspace'] = { isAuthZone: true },
    _b[RoutePath.reset_password] = { isAuthZone: false },
    _b[RoutePath.settings] = { isAuthZone: true },
    _b[RoutePath.new_password] = { isAuthZone: false },
    _b[RoutePath.alternativelanding] = { isAuthZone: false },
    _b[RoutePath.main] = { isAuthZone: false },
    _b);
