export var typeMapping = {
    0: 'filter',
    1: 'strategist',
    2: 'strategist',
    3: 'weighter',
    4: 'weighter',
    5: 'dealEntry',
    6: 'dealExit',
    7: 'dealEntry',
    8: 'dealExit',
};
export var fakeData = {
    'result_type': 'backtest',
    'task_status': 'SUCCESS',
    'data': {
        '220': {
            '2024-10-01T00-20-00': {
                '3': {
                    'base_datetime': '2024-10-01T00:20:00',
                    'left': 0.012173224915140253,
                    'left_data': {
                        'function': 'atv',
                        'high_prices': [
                            2684.51,
                            2680.33,
                            2683.7,
                            2674.5,
                            2655.0,
                            2669.2,
                            2671.8,
                            2673.41,
                            2663.5,
                            2643.53,
                            2643.08,
                            2638.94,
                            2610.01,
                            2617.46,
                            2608.97,
                        ],
                        'period': 14,
                        'low_prices': [
                            2662.21,
                            2655.5,
                            2663.64,
                            2629.73,
                            2634.2,
                            2641.0,
                            2650.49,
                            2648.0,
                            2601.12,
                            2623.08,
                            2592.42,
                            2593.73,
                            2575.0,
                            2576.58,
                            2598.58,
                        ],
                        'open_prices': [
                            2669.21,
                            2677.67,
                            2676.12,
                            2673.66,
                            2640.25,
                            2652.49,
                            2665.24,
                            2667.12,
                            2657.73,
                            2628.25,
                            2637.42,
                            2628.94,
                            2596.42,
                            2596.33,
                            2602.24,
                        ],
                        'base_datetimes': {
                            'high_prices': '2024-09-28T16:00:00Z',
                            'period': null,
                            'low_prices': '2024-09-28T16:00:00Z',
                            'open_prices': '2024-09-28T16:00:00Z',
                        },
                        'true_volatility': [
                            0.009272987336004783,
                            0.0074959269390012205,
                            0.01674483666584382,
                            0.007878041852097409,
                            0.010631519817228272,
                            0.00799552760727004,
                            0.009527130387834015,
                            0.023471157717300142,
                            0.007780842766099219,
                            0.019208165555732442,
                            0.01719704519692349,
                            0.01348395097865531,
                            0.015745302022470223,
                            0.003992713969503149,
                        ],
                        'true_volatility_mean': 0.012173224915140253,
                    },
                    'symbol': '>=',
                    'result': true,
                    'right_data': 0.01,
                    'right': 0.01,
                },
                '6': {
                    'base_datetime': '2024-10-01T00:20:00',
                    'left': 0.007039924500841649,
                    'left_data': {
                        'function': 'atv',
                        'high_prices': [
                            57781.05,
                            57018.93,
                            56289.31,
                            56962.84,
                            56906.0,
                            56906.0,
                            57116.28,
                            57028.55,
                            57043.87,
                            57043.82,
                            57624.5,
                            57607.46,
                            57682.96,
                            57964.74,
                            57850.37,
                        ],
                        'period': 14,
                        'low_prices': [
                            56700.0,
                            56066.25,
                            56066.25,
                            56289.31,
                            56701.22,
                            56864.73,
                            56864.73,
                            56907.79,
                            56893.21,
                            56153.87,
                            56844.28,
                            57189.49,
                            57239.75,
                            57500.54,
                            57850.0,
                        ],
                        'open_prices': [
                            57542.06,
                            57018.93,
                            56066.25,
                            56289.31,
                            56876.68,
                            56906.0,
                            56864.73,
                            56957.37,
                            57028.55,
                            57028.97,
                            56844.28,
                            57404.29,
                            57435.61,
                            57589.65,
                            57850.0,
                        ],
                        'base_datetimes': {
                            'high_prices': '2024-06-28T12:00:00Z',
                            'period': null,
                            'low_prices': '2024-06-28T12:00:00Z',
                            'open_prices': '2024-06-28T12:00:00Z',
                        },
                        'true_volatility': [
                            0.016708135350838753,
                            0.003978507569170359,
                            0.011965504640223851,
                            0.003600421121626629,
                            0.0007252310828383088,
                            0.004423655928727625,
                            0.0021201821643099398,
                            0.0026418346600080747,
                            0.015605226606757882,
                            0.013725567462548584,
                            0.007281163132581227,
                            0.007716641296227186,
                            0.008060476144584957,
                            6.395851339716843e-06,
                        ],
                        'true_volatility_mean': 0.007039924500841649,
                    },
                    'symbol': '>=',
                    'result': false,
                    'right_data': 0.01,
                    'right': 0.01,
                },
            },
            '2024-10-02T00-20-00': {
                '3': {
                    'base_datetime': '2024-10-01T00:20:00',
                    'left': 0.012173224915140253,
                    'left_data': {
                        'function': 'atv',
                        'high_prices': [
                            2684.51,
                            2680.33,
                            2683.7,
                            2674.5,
                            2655.0,
                            2669.2,
                            2671.8,
                            2673.41,
                            2663.5,
                            2643.53,
                            2643.08,
                            2638.94,
                            2610.01,
                            2617.46,
                            2608.97,
                        ],
                        'period': 14,
                        'low_prices': [
                            2662.21,
                            2655.5,
                            2663.64,
                            2629.73,
                            2634.2,
                            2641.0,
                            2650.49,
                            2648.0,
                            2601.12,
                            2623.08,
                            2592.42,
                            2593.73,
                            2575.0,
                            2576.58,
                            2598.58,
                        ],
                        'open_prices': [
                            2669.21,
                            2677.67,
                            2676.12,
                            2673.66,
                            2640.25,
                            2652.49,
                            2665.24,
                            2667.12,
                            2657.73,
                            2628.25,
                            2637.42,
                            2628.94,
                            2596.42,
                            2596.33,
                            2602.24,
                        ],
                        'base_datetimes': {
                            'high_prices': '2024-09-28T16:00:00Z',
                            'period': null,
                            'low_prices': '2024-09-28T16:00:00Z',
                            'open_prices': '2024-09-28T16:00:00Z',
                        },
                        'true_volatility': [
                            0.009272987336004783,
                            0.0074959269390012205,
                            0.01674483666584382,
                            0.007878041852097409,
                            0.010631519817228272,
                            0.00799552760727004,
                            0.009527130387834015,
                            0.023471157717300142,
                            0.007780842766099219,
                            0.019208165555732442,
                            0.01719704519692349,
                            0.01348395097865531,
                            0.015745302022470223,
                            0.003992713969503149,
                        ],
                        'true_volatility_mean': 0.012173224915140253,
                    },
                    'symbol': '>=',
                    'result': true,
                    'right_data': 0.01,
                    'right': 0.01,
                },
                '6': {
                    'base_datetime': '2024-10-01T00:20:00',
                    'left': 0.007039924500841649,
                    'left_data': {
                        'function': 'atv',
                        'high_prices': [
                            57781.05,
                            57018.93,
                            56289.31,
                            56962.84,
                            56906.0,
                            56906.0,
                            57116.28,
                            57028.55,
                            57043.87,
                            57043.82,
                            57624.5,
                            57607.46,
                            57682.96,
                            57964.74,
                            57850.37,
                        ],
                        'period': 14,
                        'low_prices': [
                            56700.0,
                            56066.25,
                            56066.25,
                            56289.31,
                            56701.22,
                            56864.73,
                            56864.73,
                            56907.79,
                            56893.21,
                            56153.87,
                            56844.28,
                            57189.49,
                            57239.75,
                            57500.54,
                            57850.0,
                        ],
                        'open_prices': [
                            57542.06,
                            57018.93,
                            56066.25,
                            56289.31,
                            56876.68,
                            56906.0,
                            56864.73,
                            56957.37,
                            57028.55,
                            57028.97,
                            56844.28,
                            57404.29,
                            57435.61,
                            57589.65,
                            57850.0,
                        ],
                        'base_datetimes': {
                            'high_prices': '2024-06-28T12:00:00Z',
                            'period': null,
                            'low_prices': '2024-06-28T12:00:00Z',
                            'open_prices': '2024-06-28T12:00:00Z',
                        },
                        'true_volatility': [
                            0.016708135350838753,
                            0.003978507569170359,
                            0.011965504640223851,
                            0.003600421121626629,
                            0.0007252310828383088,
                            0.004423655928727625,
                            0.0021201821643099398,
                            0.0026418346600080747,
                            0.015605226606757882,
                            0.013725567462548584,
                            0.007281163132581227,
                            0.007716641296227186,
                            0.008060476144584957,
                            6.395851339716843e-06,
                        ],
                        'true_volatility_mean': 0.007039924500841649,
                    },
                    'symbol': '>=',
                    'result': false,
                    'right_data': 0.01,
                    'right': 0.01,
                },
            },
            '2024-10-03T00-20-00': {
                '3': {
                    'base_datetime': '2024-10-01T00:20:00',
                    'left': 0.012173224915140253,
                    'left_data': {
                        'function': 'atv',
                        'high_prices': [
                            2684.51,
                            2680.33,
                            2683.7,
                            2674.5,
                            2655.0,
                            2669.2,
                            2671.8,
                            2673.41,
                            2663.5,
                            2643.53,
                            2643.08,
                            2638.94,
                            2610.01,
                            2617.46,
                            2608.97,
                        ],
                        'period': 14,
                        'low_prices': [
                            2662.21,
                            2655.5,
                            2663.64,
                            2629.73,
                            2634.2,
                            2641.0,
                            2650.49,
                            2648.0,
                            2601.12,
                            2623.08,
                            2592.42,
                            2593.73,
                            2575.0,
                            2576.58,
                            2598.58,
                        ],
                        'open_prices': [
                            2669.21,
                            2677.67,
                            2676.12,
                            2673.66,
                            2640.25,
                            2652.49,
                            2665.24,
                            2667.12,
                            2657.73,
                            2628.25,
                            2637.42,
                            2628.94,
                            2596.42,
                            2596.33,
                            2602.24,
                        ],
                        'base_datetimes': {
                            'high_prices': '2024-09-28T16:00:00Z',
                            'period': null,
                            'low_prices': '2024-09-28T16:00:00Z',
                            'open_prices': '2024-09-28T16:00:00Z',
                        },
                        'true_volatility': [
                            0.009272987336004783,
                            0.0074959269390012205,
                            0.01674483666584382,
                            0.007878041852097409,
                            0.010631519817228272,
                            0.00799552760727004,
                            0.009527130387834015,
                            0.023471157717300142,
                            0.007780842766099219,
                            0.019208165555732442,
                            0.01719704519692349,
                            0.01348395097865531,
                            0.015745302022470223,
                            0.003992713969503149,
                        ],
                        'true_volatility_mean': 0.012173224915140253,
                    },
                    'symbol': '>=',
                    'result': true,
                    'right_data': 0.01,
                    'right': 0.01,
                },
                '6': {
                    'base_datetime': '2024-10-01T00:20:00',
                    'left': 0.007039924500841649,
                    'left_data': {
                        'function': 'atv',
                        'high_prices': [
                            57781.05,
                            57018.93,
                            56289.31,
                            56962.84,
                            56906.0,
                            56906.0,
                            57116.28,
                            57028.55,
                            57043.87,
                            57043.82,
                            57624.5,
                            57607.46,
                            57682.96,
                            57964.74,
                            57850.37,
                        ],
                        'period': 14,
                        'low_prices': [
                            56700.0,
                            56066.25,
                            56066.25,
                            56289.31,
                            56701.22,
                            56864.73,
                            56864.73,
                            56907.79,
                            56893.21,
                            56153.87,
                            56844.28,
                            57189.49,
                            57239.75,
                            57500.54,
                            57850.0,
                        ],
                        'open_prices': [
                            57542.06,
                            57018.93,
                            56066.25,
                            56289.31,
                            56876.68,
                            56906.0,
                            56864.73,
                            56957.37,
                            57028.55,
                            57028.97,
                            56844.28,
                            57404.29,
                            57435.61,
                            57589.65,
                            57850.0,
                        ],
                        'base_datetimes': {
                            'high_prices': '2024-06-28T12:00:00Z',
                            'period': null,
                            'low_prices': '2024-06-28T12:00:00Z',
                            'open_prices': '2024-06-28T12:00:00Z',
                        },
                        'true_volatility': [
                            0.016708135350838753,
                            0.003978507569170359,
                            0.011965504640223851,
                            0.003600421121626629,
                            0.0007252310828383088,
                            0.004423655928727625,
                            0.0021201821643099398,
                            0.0026418346600080747,
                            0.015605226606757882,
                            0.013725567462548584,
                            0.007281163132581227,
                            0.007716641296227186,
                            0.008060476144584957,
                            6.395851339716843e-06,
                        ],
                        'true_volatility_mean': 0.007039924500841649,
                    },
                    'symbol': '>=',
                    'result': false,
                    'right_data': 0.01,
                    'right': 0.01,
                },
            },
        },
        '221': {},
        '222': {},
        '223': {},
        '69': {},
        '70': {},
        '327': {},
        '71': {},
        '328': {},
        '72': {},
        '217': {},
    },
    'non_executed_blocks': [
        69,
        70,
        327,
        71,
        328,
        72,
        217,
        220,
        221,
        222,
        223,
    ],
    'branch_blocks': [
        {
            'block_id': 220,
            'in': [
                3,
                6,
            ],
            'step_type': 1,
            'symbol': '>',
            'left_data_type': 'function',
            'right_data_type': 'attribute',
        },
        {
            'block_id': 221,
            'in': [],
            'step_type': 3,
            'symbol': '==',
            'left_data_type': {},
            'right_data_type': {},
        },
        {
            'block_id': 222,
            'in': [],
            'step_type': 5,
            'symbol': '>',
            'left_data_type': 'function',
            'right_data_type': 'value',
        },
        {
            'block_id': 223,
            'in': [],
            'step_type': 6,
            'symbol': '<',
            'left_data_type': 'function',
            'right_data_type': 'function',
        },
        {
            'block_id': 69,
            'in': [
                3,
            ],
            'step_type': 1,
            'symbol': '<',
            'left_data_type': 'function',
            'right_data_type': 'value',
        },
        {
            'block_id': 70,
            'in': [],
            'step_type': 3,
            'symbol': '==',
            'left_data_type': {},
            'right_data_type': {},
        },
        {
            'block_id': 327,
            'in': [],
            'step_type': 0,
            'symbol': '==',
            'left_data_type': 'attribute',
            'right_data_type': 'attribute',
        },
        {
            'block_id': 71,
            'in': [],
            'step_type': 5,
            'symbol': '<',
            'left_data_type': 'function',
            'right_data_type': 'function',
        },
        {
            'block_id': 328,
            'in': [],
            'step_type': 0,
            'symbol': '<',
            'left_data_type': 'attribute',
            'right_data_type': 'value',
        },
        {
            'block_id': 72,
            'in': [],
            'step_type': 6,
            'symbol': '>',
            'left_data_type': 'function',
            'right_data_type': 'function',
        },
        {
            'block_id': 217,
            'in': [],
            'step_type': 6,
            'symbol': '>',
            'left_data_type': 'function',
            'right_data_type': 'function',
        },
    ],
    'state_data': {
        'profit': 0.0,
        'deals': [],
    },
};
