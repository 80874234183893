var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { ReactComponent as SearchIcon } from '@/shared/assets/icons/Search.svg';
import { ReactComponent as CrossIcon } from '@/shared/assets/icons/Cross2.svg';
import * as S from './style';
var Search = forwardRef(function (_a, ref) {
    var value = _a.value, onChange = _a.onChange, onClear = _a.onClear, _b = _a.placeholder, placeholder = _b === void 0 ? 'Search' : _b, _c = _a.color, color = _c === void 0 ? 'gray' : _c, css = _a.css, _d = _a.size, size = _d === void 0 ? 'big' : _d, _e = _a.disabled, disabled = _e === void 0 ? false : _e, _f = _a.border, border = _f === void 0 ? true : _f;
    return (_jsxs(S.SearchWrapper, __assign({ ref: ref, "data-state-disabled": disabled, size: size, border: border }, { children: [_jsx(S.SearchInput, { css: css, placeholder: placeholder, type: 'text', size: size, color: color, value: value, onChange: onChange, disabled: disabled }), _jsx(S.SearchIcon, __assign({ size: size }, { children: _jsx(SearchIcon, {}) })), value && (_jsx(S.CrossIcon, __assign({ size: size, type: 'button', onClick: onClear }, { children: _jsx(CrossIcon, {}) })))] })));
});
export { Search };
