import { styled } from '@/app/styles/mainStyles';
import { ReactComponent as Lighting } from '@/shared/assets/icons/Lighting.svg';
export var StrategiesCard = styled('div', {
    position: 'relative',
    boxSizing: 'border-box',
    padding: '20px',
    width: '100%',
    maxWidth: '297px',
    height: '241px',
    borderRadius: '24px',
    overflow: 'hidden',
    transition: 'all 0.3s',
    fontFamily: '$afacad',
    cursor: 'pointer',
    '&:hover': {
        transform: 'scale(1.02)',
    },
    '@mobile': {
        maxWidth: '214px',
        height: '120px',
        borderRadius: '15px',
        padding: '12.45px',
        '&:hover': {
            transform: 'none',
        },
    },
    '@tablet': {
        maxWidth: '214px',
        height: '120px',
        borderRadius: '15px',
        padding: '12.45px',
        '&:hover': {
            transform: 'none',
        },
    },
    variants: {
        type: {
            mainLanding: {
                backgroundColor: '#D1E4FC',
            },
            alternativeLanding: {
                backgroundColor: '#161414',
            },
        },
    },
});
export var StrategiesCardWrapper = styled('div', {
    position: 'relative',
    zIndex: 5,
    height: '100%',
});
export var StrategiesImgOverlay = styled('div', {
    boxSizing: 'border-box',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(91, 91, 91, .5)',
    zIndex: 2,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
});
export var StrategiesImage = styled('img', {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    objectFit: 'cover',
    objectPosition: 'top center',
    '@mobile': {
        width: '15px',
        height: '15px',
    },
    '@tablet': {
        width: '15px',
        height: '15px',
    },
});
export var StrategiesText = styled('p', {
    fontFamily: '$afacad',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    '@mobile': {
        fontSize: '8.7px',
        lineHeight: '12.45px',
    },
    '@tablet': {
        fontSize: '8.7px',
        lineHeight: '12.45px',
    },
    variants: {
        type: {
            mainLanding: {
                color: '$primaryText',
            },
            alternativeLanding: {
                color: '$alwaysWhite',
            },
        },
    },
});
export var StrategiesBackground = styled('img', {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    filter: 'blur(7.5px)',
});
export var StrategiesHeader = styled('div', {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    justifyContent: 'space-between',
    '@mobile': {
        marginBottom: '7.5px',
    },
    '@tablet': {
        marginBottom: '7.5px',
    },
});
export var StrategiesAuthor = styled('div', {
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
});
export var StrategiesAuthorSpan = styled('span', {
    color: '$textDisabled',
    marginLeft: '8px',
    '@mobile': {
        marginLeft: '5px',
    },
    '@tablet': {
        marginLeft: '5px',
    },
});
export var StrategiesTitle = styled('h3', {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '28px',
    variants: {
        type: {
            mainLanding: {
                color: '$primaryText',
            },
            alternativeLanding: {
                color: '$alwaysWhite',
            },
        },
    },
    '@mobile': {
        fontSize: '11.2px',
        lineHeight: '15px',
    },
    '@tablet': {
        fontSize: '11.2px',
        lineHeight: '15px',
    },
});
export var StrategiesBody = styled('div', {
    height: 'calc(100% - 32px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@mobile': {
        height: 'calc(100% - 25px)',
    },
    '@tablet': {
        height: 'calc(100% - 25px)',
    },
});
export var StrategiesForecastWrapper = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
});
export var StrategiesForecast = styled('p', {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '36px',
    variants: {
        color: {
            green: {
                color: '$green',
            },
            red: {
                color: '$inputBorderError',
            },
            gray: {
                color: '$inputBorderHover',
            },
        },
    },
    '@mobile': {
        fontSize: '15px',
        lineHeight: '20px',
    },
    '@tablet': {
        fontSize: '15px',
        lineHeight: '20px',
    },
});
export var StrategiesRisk = styled('div', {
    display: 'flex',
    padding: '4px 8px',
    gap: '4px',
    alignItems: 'center',
    borderRadius: '20px',
    '@mobile': {
        padding: '2.5px 5px',
        gap: '2.5px',
        borderRadius: '13px',
        height: '17px',
    },
    '@tablet': {
        padding: '2.5px 5px',
        gap: '2.5px',
        borderRadius: '13px',
        height: '17px',
    },
    variants: {
        type: {
            mainLanding: {
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
            },
            alternativeLanding: {
                backgroundColor: 'rgba(0, 0, 0, .5)',
            },
        },
    },
});
export var LightningImg = styled(Lighting, {
    width: '20px',
    height: '20px',
    '@mobile': {
        width: '15px',
        height: '15px',
    },
    '@tablet': {
        width: '15px',
        height: '15px',
    },
    variants: {
        type: {
            mainLanding: {
                color: '#E9865B',
            },
            alternativeLanding: {
                color: '$alwaysWhite',
            },
        },
    },
});
export var StrategiesEmptyAvatar = styled('div', {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    backgroundColor: '$aquamarine',
    '@mobile': {
        width: '15px',
        height: '15px',
    },
    '@tablet': {
        width: '15px',
        height: '15px',
    },
});
export var BlueLight = styled('div', {
    WebkitBackfaceVisibility: 'hidden',
    WebkitPerspective: '1000',
    WebkitTransform: 'translate3d(0, 0, 0)',
    backfaceVisibility: 'hidden',
    perspective: '1000',
    transform: 'translate3d(0, 0, 0)',
    position: 'absolute',
    borderRadius: '100%',
    filter: 'blur(52px)',
    zIndex: '1',
    variants: {
        style: {
            default: {},
        },
        type: {
            mainLanding: {
                backgroundColor: '#4106E2',
                opacity: '20%',
                filter: 'blur(70px)',
            },
            alternativeLanding: {
                backgroundColor: '#00a5bb',
            },
        },
    },
});
export var GreenLight = styled('div', {
    WebkitBackfaceVisibility: 'hidden',
    WebkitPerspective: '1000',
    WebkitTransform: 'translate3d(0, 0, 0)',
    backfaceVisibility: 'hidden',
    perspective: '1000',
    transform: 'translate3d(0, 0, 0)',
    position: 'absolute',
    borderRadius: '100%',
    filter: 'blur(52px)',
    zIndex: '2',
    variants: {
        type: {
            mainLanding: {
                backgroundColor: '#00A5BB',
                opacity: '20%',
                filter: 'blur(70px)',
            },
            alternativeLanding: {
                backgroundColor: '#0b8216',
            },
        },
    },
});
export var PurpleLight = styled('div', {
    WebkitBackfaceVisibility: 'hidden',
    WebkitPerspective: '1000',
    WebkitTransform: 'translate3d(0, 0, 0)',
    backfaceVisibility: 'hidden',
    perspective: '1000',
    transform: 'translate3d(0, 0, 0)',
    position: 'absolute',
    borderRadius: '100%',
    filter: 'blur(52px)',
    zIndex: '3',
    variants: {
        type: {
            mainLanding: {
                backgroundColor: '#565BF1',
                opacity: '20%',
                filter: 'blur(70px)',
            },
            alternativeLanding: {
                backgroundColor: '#DE06E2',
            },
        },
    },
});
