import { styled } from '@stitches/react';
export var TabsWrapper = styled('div', {
    boxSizing: 'border-box',
    display: 'inline-block',
    lineHeight: 0,
    verticalAlign: 'center',
    variants: {
        styleType: {
            default: {
                backgroundColor: '$controlsBgSecondaryDefault',
                borderRadius: '20px',
                border: '1px solid $controlsStrokeDefault',
            },
            tableRun: {
                borderRadius: '0px',
                border: 'none',
            },
            sideBarRun: {
                display: 'block',
                borderRadius: '0px',
                border: 'none',
            },
            functionRun: {
                display: 'flex',
                border: 'none',
                gap: '8px',
            },
            chips: {
                display: 'flex',
                borderRadius: '100px',
                border: 'none',
                gap: '12px',
            },
        },
    },
});
export var TabsInput = styled('input', {
    transition: 'background-color .2s',
    display: 'none',
    variants: {
        styleType: {
            default: {
                '&:checked + label': {
                    color: '$textInvertedWhite',
                    backgroundColor: '$controlsBgPrimaryDefault',
                },
            },
            tableRun: {
                '&:checked + label': {
                    color: '$primaryText',
                    borderBottom: '1px solid $primaryText',
                },
            },
            sideBarRun: {
                '&:checked + label': {
                    background: '$controlsBgSecondaryDefault',
                    color: '$primaryText',
                    fontWeight: 400,
                    borderBottom: 'none',
                    borderRadius: '8px',
                    height: '35px',
                    gap: '8px',
                },
            },
            functionRun: {
                '&:checked + label': {
                    background: '#242B31',
                    color: '#FFFFFF',
                    padding: '8px 16px',
                    borderRadius: '100px',
                    border: '1px solid #242B31',
                },
                '&:not(:checked) + label': {
                    border: '1px solid #D0D5DD',
                },
            },
            chips: {
                '&:checked + label': {
                    background: '$controlsBgPrimaryDefault',
                    color: '$textInvertedWhite',
                    padding: '8px 16px',
                    border: '1px solid $controlsStrokeHover',
                },
                '&:not(:checked) + label': {
                    color: '$primaryText',
                    border: '1px solid $controlsStrokeDefault',
                },
            },
        },
    },
});
export var TabsLabel = styled('label', {
    display: 'inline-flex',
    boxSizing: 'border-box',
    cursor: 'pointer',
    fontWeight: 500,
    userSelect: 'none',
    transition: 'all .2s',
    padding: 0,
    variants: {
        type: {
            default: {
                fontSize: '20px',
                padding: '12px 24px',
                lineHeight: '28px',
                borderRadius: '20px',
                color: '$primaryText',
                backgroundColor: '$controlsBgSecondaryDefault',
                '&:hover': {
                    background: '$controlsBgSecondaryHover',
                },
            },
            small: {
                height: '36px',
                fontSize: '16px',
                padding: '7px 16px',
                lineHeight: '22px',
                borderRadius: '20px',
                color: '$primaryText',
                backgroundColor: '$controlsBgSecondaryDefault',
                '&:hover': {
                    background: '$controlsBgSecondaryHover',
                },
            },
            tableRun: {
                fontSize: '14px',
                padding: '8px 12px',
                lineHeight: '22px',
                borderRadius: '0px',
                color: '$textSecondary',
            },
            strategies: {
                fontSize: '20px',
                padding: '8px 16px',
                lineHeight: '24px',
                borderRadius: '0px',
                color: '$textSecondary',
            },
            sideBarRun: {
                width: '100%',
                height: '32px',
                fontSize: '14px',
                fontWeight: 400,
                fontFamily: 'Montserrat',
                padding: '4px 8px 4px 16px ',
                lineHeight: '22px',
                borderRadius: '0px',
                color: '#64748B',
                backgroundColor: '$bgSecondary',
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
            },
            functionRun: {
                padding: '8px 16px',
                fontSize: '16px',
                textAlign: 'center',
                color: '$primaryText',
                lineHeight: '22px',
                borderRadius: '100px',
                '&:hover': {
                    background: '#E7EAEE',
                },
            },
            chips: {
                padding: '8px 16px',
                fontSize: '16px',
                textAlign: 'center',
                color: '$primaryText',
                lineHeight: '22px',
                borderRadius: '100px',
                backgroundColor: '$controlsBgSecondaryDefault',
                '&:hover': {
                    background: '$controlsBgSecondaryHover',
                },
            },
        },
    },
});
