import { styled } from '@/app/styles/mainStyles';
export var Text = styled('p', {
    display: 'inline-block',
    margin: 0,
    color: '$primaryText',
    variants: {
        type: {
            header: {
                fontSize: '16px',
                color: '$header',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '22px',
            },
            primary: {
                fontSize: '20px',
                color: '$black700',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '28px',
            },
            title: {
                fontSize: '36px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '48px',
                color: '$primaryText',
            },
            hint: {
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '22px',
                color: '$textSecondary',
            },
            label: {
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
                color: '$primaryText',
            },
            errorInput: {
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                color: '$error',
                lineHeight: '22px',
            },
            labelTable: {
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                color: '$primaryText',
                lineHeight: '16px',
            },
            subLabelTable: {
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                color: '$inputBorderFocus',
                lineHeight: '16px',
            },
            smallText: {
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                color: '$textSecondary',
                lineHeight: '22px',
            },
        },
        userSelect: {
            noneSelect: {
                userSelect: 'none',
            },
        },
        ellipse: {
            true: {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
    },
});
