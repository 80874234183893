var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import Cookies from 'universal-cookie';
import handlerErrors from '@/shared/helpers/handlerErrors/handlerErrors';
var cookies = new Cookies();
var getBaseUrl = function () {
    return 'https://clownfish-app-mjp59.ondigitalocean.app/api/v1/' || process.env.BASE_URL;
};
export var BASE_URL = getBaseUrl();
console.log('BASE_URL', BASE_URL);
export var api = axios.create({
    baseURL: BASE_URL,
});
api.interceptors.request.use(function (config) {
    var token = cookies.get('access');
    if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = "Bearer ".concat(token);
    }
    return config;
});
api.interceptors.response.use(function (config) {
    return config;
}, function (error) { return __awaiter(void 0, void 0, void 0, function () {
    var originalRequest, response, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                originalRequest = error.config;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 7]);
                if (!(error.response.status === 401 && error.config && !error.config._isRetry)) return [3 /*break*/, 4];
                originalRequest._isRetry = true;
                return [4 /*yield*/, axios.post("".concat(BASE_URL, "user/token/"), {
                        refresh: cookies.get('refresh'),
                    })];
            case 2:
                response = _a.sent();
                cookies.set('access', response.data.access, { path: '/', maxAge: 15 * 60 });
                return [4 /*yield*/, api.request(originalRequest)];
            case 3: return [2 /*return*/, _a.sent()];
            case 4:
                if (error.response.data) {
                    handlerErrors(error.response.data);
                }
                _a.label = 5;
            case 5: return [3 /*break*/, 7];
            case 6:
                e_1 = _a.sent();
                console.log('e', e_1);
                return [3 /*break*/, 7];
            case 7: throw error;
        }
    });
}); });
