import { styled } from '@/app/styles/mainStyles';
export var MainLayoutWrapper = styled('div', {
    variants: {
        background: {
            white: {
                backgroundColor: '$white',
            },
            gray: {
                backgroundColor: '$bgSecondary',
            },
        },
    },
});
export var MainLayout = styled('div', {
    maxWidth: '1440px',
    boxSizing: 'border-box',
    margin: '0px auto 0 auto',
    padding: '80px 95px',
    minHeight: '100%',
    '@mobile': {
        padding: '0 16px',
        margin: '60px auto 0 auto',
    },
    '@tablet': {
        padding: '0 16px',
        margin: '60px auto 0 auto',
    },
});
