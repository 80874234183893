import { styled } from '@/app/styles/mainStyles';
import { ReactComponent as Logo } from '@/shared/assets/icons/greenLOGO.svg';
import { ReactComponent as LogoShort } from '@/shared/assets/icons/logoGreenSgort.svg';
import { ReactComponent as GreenArrowIcon } from '@/shared/assets/icons/greenArrow.svg';
import { ReactComponent as Menu } from 'shared/assets/icons/BurgerMenu.svg';
import { ReactComponent as Cross } from 'shared/assets/icons/Cross.svg';
import { Button } from '@/shared/ui/Button/style';
import { Text } from '@/shared/ui/Text/style';
import { keyframes } from '@stitches/react';
import { Input } from '@/shared/ui';
var scrollAnimation = keyframes({
    '0%': { transform: 'translateX(0%)' },
    '100%': { transform: 'translateX(-79.486%)' },
});
var LogosAnimation = keyframes({
    '0%': { transform: 'translateX(0%)' },
    '100%': { transform: 'translateX(-50.85%)' },
});
export var MenuOverlay = styled('div', {
    background: 'rgba(0, 0, 0, 0.8)',
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    backdropFilter: 'Blur(0px)',
    transition: 'opacity 0.3s ease, backdropFilter 0.3s ease',
    variants: {
        isOpen: {
            true: {
                backdropFilter: 'Blur(15px)',
                zIndex: '98',
                opacity: '1',
            },
            false: {
                backdropFilter: 'Blur(0px)',
                opacity: '0',
            },
        },
    },
});
export var BurgerContent = styled('div', {
    display: 'flex',
    gap: '8px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    margin: 'auto auto',
});
export var BurgerLink = styled('a', {
    color: '#D6F809',
    textDecoration: 'none',
    fontFamily: '$afacad',
    fontSize: '14px',
    lineHeight: '143%',
    textAlign: 'center',
    fontWeight: 500,
    padding: '12px 16px',
    width: '100%',
});
export var Container = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    background: '#161819',
    maxHeight: '100%',
});
export var ScrollableContainer = styled('div', {
    display: 'flex',
    zIndex: 7,
});
export var Header = styled('header', {
    display: 'flex',
    height: '80px',
    width: '100%',
    boxSizing: 'border-box',
    alignItems: 'center',
    borderBottom: '1px solid rgba(231, 234, 238, 0.2)',
    zIndex: 99,
    position: 'fixed',
    top: 0,
    transition: 'all 0.3s ease',
    variants: {
        scrolling: {
            true: {
                backdropFilter: 'blur(32px)',
                background: 'rgba(0, 0, 0, 0.3)',
            },
        },
        isMenuOpen: {
            true: {
                borderBottom: 0,
                backdropFilter: 'blur(0px)',
                background: 'transparent',
            },
        },
    },
    '@mobile': {
        height: '52px',
    },
    '@tablet': {
        height: '52px',
    },
});
export var HeaderContent = styled('div', {
    display: 'flex',
    padding: '18px 100px',
    alignItems: 'center',
    maxWidth: '1440px',
    width: '100%',
    margin: '0 auto',
    height: '100%',
    '@mobile': {
        padding: '10px 16px',
    },
    '@tablet': {
        padding: '10px 16px',
    },
});
export var Burger = styled(Menu, {
    '@desktop': {
        display: 'none',
    },
    color: '$alwaysWhite',
});
export var CrossIcon = styled(Cross, {
    '@desktop': {
        display: 'none',
    },
    color: '$alwaysWhite',
});
export var LogoContainer = styled('div', {
    display: 'flex',
    flex: 1,
});
export var NavLinks = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    margin: '0 24px 0 24px',
    '@mobile': {
        display: 'none',
    },
    '@tablet': {
        display: 'none',
    },
});
export var ButtonContainer = styled('div', {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
});
export var Link = styled('a', {
    color: '$alwaysWhite',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '137%',
    cursor: 'pointer',
    textDecoration: 'none',
});
export var HeaderButton = styled('a', {
    background: '#D6F809',
    padding: '12px 20px',
    boxSizing: 'border-box',
    borderRadius: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    color: '#1D2023',
    fontFamily: '$afacad',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '117%',
    '@mobile': {
        padding: '6px 12px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
    },
    '@tablet': {
        padding: '6px 12px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
    },
});
export var StyledButton = styled('a', {
    background: '#D6F809',
    height: '52px',
    padding: '12px 20px',
    boxSizing: 'border-box',
    borderRadius: '12px',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    color: '#1D2023',
    fontFamily: '$afacad',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '117%',
    '@mobile': {
        width: '100%',
    },
    '@tablet': {
        width: '100%',
    },
});
export var DesktopLogo = styled(Logo, {
    display: 'flex',
    alignItems: 'center',
    color: '#FFF',
    '@mobile': {
        display: 'none',
    },
    '@tablet': {
        display: 'none',
    },
});
export var MobileLogo = styled(LogoShort, {
    display: 'flex',
    alignItems: 'center',
    color: '#FFF',
    '@desktop': {
        display: 'none',
    },
});
export var FirstScreen = styled('div', {
    position: 'relative',
    display: 'flex',
    height: '95vh',
    marginTop: '4%',
    '@mobile': {
        height: '800px',
        marginTop: '0px',
    },
    '@tablet': {
        height: '800px',
        marginTop: '0px',
    },
});
export var FirstScreenContent = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 6,
    margin: 'auto auto',
    overflow: 'hidden',
    '@mobile': {
        padding: '0 16px',
        margin: 'unset',
    },
    '@tablet': {
        padding: '0 16px',
    },
});
export var BlocksImg = styled('img', {
    marginBottom: '75px',
    userSelect: 'none',
    pointerEvents: 'none',
    boxSizing: 'content-box',
    '@mobile': {
        width: '233px',
        height: '252px',
        margin: '135px auto 47px auto',
    },
    '@tablet': {
        marginTop: '135px',
        marginBottom: '83px',
    },
});
export var FirstTitle = styled(Text, {
    fontFamily: '$afacad',
    fontSize: '62px',
    color: '$alwaysWhite',
    fontWeight: 600,
    lineHeight: '120%',
    textAlign: 'center',
    '@mobile': {
        fontSize: '48px',
        lineHeight: '52px',
    },
    '@tablet': {
        fontSize: '48px',
        lineHeight: '52px',
    },
});
export var FirstSubTitle = styled(Text, {
    fontFamily: '$afacad',
    fontSize: '32px',
    color: '$alwaysWhite',
    opacity: '50%',
    fontWeight: 400,
    lineHeight: '120%',
    textAlign: 'center',
    '@mobile': {
        fontSize: '16px',
        lineHeight: '24px',
        maxWidth: '288px',
    },
    '@tablet': {
        fontSize: '16px',
        lineHeight: '24px',
        maxWidth: '288px',
    },
});
export var ButtonsWrapper = styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    margin: '52px 0 0 0',
    width: '100%',
    justifyContent: 'center',
    '@mobile': {
        flexDirection: 'column',
        marginTop: '32px',
    },
    '@tablet': {
        flexDirection: 'column',
        marginTop: '32px',
    },
});
export var TransparentButton = styled('a', {
    display: 'flex',
    background: 'transparent',
    color: '#D6F809',
    width: '187px',
    height: '52px',
    textDecoration: 'none',
    borderRadius: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    fontFamily: '$afacad',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '117%',
    transition: 'background 0.2s linear',
    '&:hover': {
        background: 'rgba(255, 255, 255, 0.1)',
    },
    '@mobile': {
        width: '100%',
        order: 1,
        fontSize: '14px',
    },
    '@tablet': {
        width: '100%',
        margin: '0 16px',
        order: 1,
        fontSize: '14px',
    },
});
export var StartButton = styled('a', {
    display: 'flex',
    background: '#D6F809',
    height: '52px',
    padding: '12px 16px',
    boxSizing: 'border-box',
    borderRadius: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    color: '#1D2023',
    fontFamily: '$afacad',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '117%',
    '@mobile': {
        width: '100%',
        fontSize: '14px',
    },
    '@tablet': {
        width: '100%',
        fontSize: '14px',
    },
});
export var AboutScreen = styled('div', {
    display: 'flex',
    overflowX: 'auto',
    alignItems: 'center',
    height: '100vh',
    // position: 'sticky',
    // top: 0,
    backgroundColor: '#161819',
    zIndex: 9,
    padding: '0 16px',
    cursor: 'grab',
    '&::-webkit-scrollbar': {
        display: 'none',
        alignItems: 'unset',
    },
    '@mobile': {
        height: 'fit-content',
        alignItems: 'unset',
    },
    '@tablet': {
        height: 'fit-content',
        alignItems: 'unset',
    },
});
export var BlocksWrapper = styled('div', {
    display: 'flex',
    maxWidth: '1440px',
    margin: 'auto auto',
    gap: '20px',
    transition: 'transform 0.5s ease-in-out',
    '& > *': {
        flex: '0 0 auto',
    },
    '&::after': {
        content: '""',
        flex: '0 0 20px',
    },
    '@mobile': {
        width: '100vw',
        gap: '8px',
        marginTop: '80px',
        '&::after': {
            content: '',
            flex: 'unset',
        },
    },
    '@tablet': {
        width: '100vw',
        gap: '8px',
        marginTop: '80px',
        '&::after': {
            content: '',
            flex: 'unset',
        },
    },
});
export var AboutBlock = styled('div', {
    display: 'flex',
    position: 'relative',
    gap: '41px',
    maxWidth: '1138px',
    width: '100%',
    height: '557px',
    borderRadius: '32px',
    padding: '68px 48px',
    boxSizing: 'border-box',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.5)',
    userSelect: 'none',
    '@mobile': {
        width: 'calc(100% - 32px)',
        height: 'fit-content',
        padding: '24px',
        gap: '23px',
        borderRadius: '24px',
        flexDirection: 'column',
    },
    '@tablet': {
        width: '100%',
        height: 'fit-content',
        padding: '24px',
        gap: '23px',
        borderRadius: '24px',
        flexDirection: 'column',
    },
});
export var CardTextWrapper = styled('div', {
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
    '@mobile': {
        gap: '12px',
    },
    '@tablet': {
        gap: '12px',
    },
});
export var Video = styled('video', {
    width: '648px',
    height: '364px',
    borderRadius: '8px',
    border: '5px solid rgba(222, 222, 222, 0.1)',
    zIndex: 2,
    '@mobile': {
        width: 'auto',
        height: '135px',
        objectFit: 'cover',
        border: '2px solid rgba(222, 222, 222, 0.1)',
        order: -1,
    },
    '@tablet': {
        width: '100%',
        border: '2px solid rgba(222, 222, 222, 0.1)',
        objectFit: 'cover',
        order: -1,
    },
});
export var Light = styled('div', {
    display: 'flex',
    width: '648px',
    height: '365px',
    position: 'absolute',
    background: 'linear-gradient(147deg, rgba(0, 165, 187, 0.6) 0%, rgba(11, 130, 22, 0.6) 47.25%, rgba(222, 6, 226, 0.6) 100%)',
    top: 72,
    right: 52,
    zIndex: 1,
    filter: 'blur(61.9px)',
    opacity: 0.4,
    '@mobile': {
        height: '135px',
        width: '86%',
        top: 23,
        right: 23,
    },
    '@tablet': {
        width: '95%',
        right: 28,
        top: 10,
    },
});
export var CardTitle = styled(Text, {
    fontFamily: '$afacad',
    fontSize: '40px',
    color: '$alwaysWhite',
    fontWeight: 600,
    lineHeight: '120%',
    '@mobile': {
        fontSize: '32px',
    },
    '@tablet': {
        fontSize: '32px',
    },
});
export var CardSubtitle = styled(Text, {
    fontFamily: '$afacad',
    fontSize: '24px',
    color: '$alwaysWhite',
    fontWeight: 500,
    lineHeight: '120%',
    opacity: '0.5',
    '@mobile': {
        fontSize: '16px',
    },
    '@tablet': {
        fontSize: '16px',
    },
});
export var IntegrationsScreen = styled('div', {
    display: 'flex',
    height: '260px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    width: 'max-content',
    background: '#161819',
    zIndex: 6,
    '@mobile': {
        height: '150px',
    },
    '@tablet': {
        height: '150px',
    },
});
export var LogosWrapper = styled('div', {
    display: 'flex',
    height: '260px',
    padding: '110px 0',
    gap: '60px',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '200%',
    animation: "".concat(LogosAnimation, " 20s linear infinite"),
    '@mobile': {
        gap: '45px',
    },
    '@tablet': {
        gap: '45px',
    },
});
export var StrategiesScreen = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    height: '1000px',
    backgroundColor: '#161819',
    zIndex: '6',
    '@mobile': {
        height: '700px',
    },
    '@tablet': {
        height: '700px',
    },
});
export var ScreenTitleWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    padding: '0 100px',
    maxWidth: '1440px',
    width: '100%',
    margin: '0 auto',
    '@mobile': {
        padding: '0 16px',
        width: '85%',
        gap: '12px',
        margin: 'unset',
    },
    '@tablet': {
        padding: '0 16px',
        width: '80%',
        gap: '12px',
        margin: 'unset',
    },
});
export var ScreenTitle = styled(Text, {
    fontFamily: '$afacad',
    fontSize: '54px',
    color: '$alwaysWhite',
    fontWeight: 600,
    lineHeight: '64.8px',
    '@mobile': {
        fontSize: '36px',
        lineHeight: '48px',
    },
    '@tablet': {
        fontSize: '36px',
        lineHeight: '48px',
    },
});
export var ScreenSubTitle = styled(Text, {
    fontFamily: '$afacad',
    fontSize: '32px',
    color: '$alwaysWhite',
    fontWeight: 400,
    lineHeight: '120%',
    opacity: '0.5',
    '@mobile': {
        fontSize: '16px',
        lineHeight: '24px',
    },
    '@tablet': {
        fontSize: '16px',
        lineHeight: '24px',
    },
});
export var StrategiesContent = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto 0',
    height: 'fit-content',
    gap: '80px',
    marginBottom: '250px',
    '@mobile': {
        height: '596px',
        gap: '40px',
        marginBottom: '-50px',
    },
    '@tablet': {
        height: '476px',
        gap: '40px',
        marginBottom: '-50px',
    },
});
export var StrategiesCardWrapper = styled('div', {
    display: 'flex',
    gap: '20px',
    width: 'fit-content',
    // padding: '0 100px',
    // marginRight: '200px',
    animation: "".concat(scrollAnimation, " 30s linear infinite"),
    transition: 'filter 0.2s linear',
    '&:hover > *': {
        filter: 'brightness(70%)',
    },
    '& > *:hover': {
        filter: 'brightness(100%)',
    },
    '&:hover': {
        animationPlayState: 'paused',
    },
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    '& > *': {
        flex: '0 0 auto',
    },
    '@mobile': {
        gap: '8px',
        animation: 'none',
        overflowX: 'auto',
        padding: '0 16px',
        width: '100%',
        '&::after': {
            content: '""',
            flex: '0 0 20px',
        },
    },
    '@tablet': {
        gap: '8px',
        animation: 'none',
        overflowX: 'auto',
        width: '100%',
        padding: '0 16px',
    },
});
export var ServiceScreen = styled('div', {
    display: 'flex',
    height: '1098px',
    position: 'relative',
    zIndex: '5',
    '@mobile': {
        height: '1100px',
        width: '100%',
    },
    '@tablet': {
        height: '800px',
        width: '100%',
    },
});
export var LinksScreenContent = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto auto',
    alignItems: 'center',
    gap: '136px',
    zIndex: 6,
    '@mobile': {
        padding: '0 16px',
        gap: '40px',
        width: '100%',
        // marginTop: '150px',
    },
    '@tablet': {
        padding: '0 16px',
        gap: '40px',
        width: '100%',
    },
});
export var TitleWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: '15px',
    '@mobile': {
        width: '85%',
    },
    '@tablet': {
        width: '85%',
    },
});
export var ServiceBlockWrapper = styled('div', {
    display: 'flex',
    position: 'relative',
    gap: '21px',
    '@mobile': {
        flexDirection: 'column',
        width: '100%',
    },
    '@tablet': {
        flexDirection: 'column',
        width: '100%',
    },
});
export var ServiceBlock = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '609.5px',
    height: '232px',
    borderRadius: '32px',
    padding: '32px',
    boxSizing: 'border-box',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    '@mobile': {
        width: '100%',
        height: 'fit-content',
        padding: '24px',
    },
    '@tablet': {
        width: '100%',
        height: 'fit-content',
        padding: '24px',
    },
});
export var ServiceHeader = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
});
export var ServiceButton = styled(Button, {
    height: '40px',
    padding: '8px 12px',
    gap: '8px',
    fontFamily: '$afacad',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    '@mobile': {
        opacity: 0,
        width: 0,
        height: 0,
    },
    '@tablet': {
        opacity: 0,
        width: 0,
        height: 0,
    },
});
export var MobileServiceButton = styled(Button, {
    height: '40px',
    padding: '12px 16px',
    gap: '8px',
    fontFamily: '$afacad',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: '12px',
    '@desktop': {
        display: 'none',
    },
});
export var GreenArrow = styled(GreenArrowIcon, {
    width: '22px',
    height: '23px',
    '@mobile': {
        width: '19px',
        height: '20px',
    },
    '@tablet': {
        width: '19px',
        height: '20px',
    },
});
export var ServiceTitle = styled(Text, {
    fontFamily: '$afacad',
    fontSize: '32px',
    color: '$alwaysWhite',
    fontWeight: 500,
    lineHeight: '38.4px',
    '@mobile': {
        fontSize: '28px',
        lineHeight: '33.6px',
    },
    '@tablet': {
        fontSize: '28px',
        lineHeight: '33.6px',
    },
});
export var ServicePoint = styled(Text, {
    fontFamily: '$afacad',
    fontSize: '18px',
    color: '$alwaysWhite',
    fontWeight: 500,
    lineHeight: '21.6px',
    width: '100%',
    '@mobile': {
        fontSize: '16px',
        lineHeight: '19.2px',
    },
    '@tablet': {
        fontSize: '16px',
        lineHeight: '19.2px',
    },
});
export var PriceScreen = styled('div', {
    display: 'flex',
    height: '736px',
    '@mobile': {
        height: '736px',
    },
    '@tablet': {
        height: '736px',
    },
});
export var PriceScreenContent = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    gap: '72px',
    zIndex: 6,
    padding: '0 16px',
    '@mobile': {
        overflow: 'hidden',
        gap: '40px',
    },
    '@tablet': {
        overflow: 'hidden',
        gap: '40px',
    },
});
export var BannersContainer = styled('div', {
    display: 'flex',
    width: '100%',
    gap: '20px',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    '& > *': {
        flex: '0 0 auto',
    },
    '@mobile': {
        overflow: 'auto',
        gap: '8px',
        width: '100%',
        padding: '0 16px',
    },
    '@tablet': {
        overflow: 'auto',
        gap: '8px',
        width: '100%',
        padding: '0 16px',
    },
});
export var PlanCard = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 24px',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    alignItems: 'flex-start',
    borderRadius: '24px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    width: '400px',
    height: '540px',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    fontFamily: '$afacad',
    '@mobile': {
        height: 'fit-content',
        width: 'calc(100% - 32px)',
    },
    '@tablet': {
        height: 'fit-content',
        width: 'calc(100% - 32px)',
        '&:first-child': {
            marginLeft: '16px',
        },
        '&:last-child': {
            marginRight: '16px',
        },
    },
});
export var PriceCardTitle = styled(Text, {
    fontFamily: '$montserrat',
    fontSize: '28px',
    lineHeight: '42px',
    fontWeight: 600,
    color: '$alwaysWhite',
    '@mobile': {
        fontSize: '24px',
        lineHeight: '32px',
    },
    '@tablet': {
        fontSize: '24px',
        lineHeight: '32px',
    },
});
export var PriceCardDescription = styled(Text, {
    fontSize: '20px',
    lineHeight: '26px',
    height: '54px',
    fontWeight: 400,
    color: '$alwaysWhite',
    '@mobile': {
        fontSize: '14px',
        lineHeight: '20px',
    },
    '@tablet': {
        fontSize: '14px',
        lineHeight: '20px',
    },
});
export var CardPrice = styled(Text, {
    fontSize: '80px',
    fontWeight: 500,
    lineHeight: '104px',
    '@mobile': {
        fontSize: '60px',
    },
    '@tablet': {
        fontSize: '60px',
    },
});
export var MonthText = styled(Text, {
    lineHeight: '57px',
    fontWeight: 400,
    '@mobile': {
        fontSize: '14px',
        lineHeight: '75px',
    },
    '@tablet': {
        fontSize: '14px',
        lineHeight: '75px',
    },
});
export var CardIncludes = styled(Text, {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 500,
    '@mobile': {
        fontSize: '18px',
        lineHeight: '24px',
    },
    '@tablet': {
        fontSize: '18px',
        lineHeight: '24px',
    },
});
export var OnceInclude = styled(Text, {
    fontSize: '20px',
    lineHeight: '22px',
    fontWeight: 400,
    '@mobile': {
        fontSize: '14px',
        lineHeight: '20px',
    },
    '@tablet': {
        fontSize: '14px',
        lineHeight: '20px',
    },
});
export var FAQScreen = styled('div', {
    display: 'flex',
    height: '1000px',
    zIndex: 6,
    '@mobile': {
        padding: '0 16px',
        height: '650px',
    },
    '@tablet': {
        padding: '0 16px',
        height: '476px',
    },
});
export var FAQScreenContent = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto auto',
    gap: '72px',
    maxWidth: '614px',
    width: '100%',
    '@mobile': {
        maxWidth: '100%',
        gap: '40px',
    },
    '@tablet': {
        maxWidth: '100%',
        gap: '40px',
    },
});
export var FormScreen = styled('div', {
    display: 'flex',
    height: '729px',
    zIndex: 6,
    '@mobile': {
        height: '600px',
    },
    '@tablet': {
        height: '600px',
    },
});
export var FormScreenContent = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    alignItems: 'center',
    gap: '72px',
    width: '100%',
    '@mobile': {
        textAlign: 'center',
        padding: '0 16px',
        gap: '40px',
    },
    '@tablet': {
        textAlign: 'center',
        padding: '0 16px',
        gap: '40px',
    },
});
export var InputsWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    maxWidth: '614px',
    width: '100%',
    '@mobile': {
        maxWidth: '100%',
    },
    '@tablet': {
        maxWidth: '100%',
    },
});
export var FormWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '36px',
    width: '100%',
    alignItems: 'center',
});
export var StyledInput = styled(Input, {
    background: 'rgba(67, 75, 88, 1)',
    color: '$alwaysWhite',
    '@mobile': {
        padding: '13px 16px',
    },
    '@tablet': {
        padding: '13px 16px',
    },
});
export var SuccessFormText = styled(Text, {
    fontFamily: '$afacad',
    fontSize: '32px',
    color: '$alwaysWhite',
    fontWeight: 400,
    lineHeight: '38.4px',
    textAlign: 'center',
    maxWidth: '714px',
    '@mobile': {
        fontSize: '16px',
        lineHeight: '24px',
    },
    '@tablet': {
        fontSize: '16px',
        lineHeight: '24px',
    },
});
export var SuccessFormLink = styled(Text, {
    fontFamily: '$afacad',
    color: '#D6F809',
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: '38.4px',
    '@mobile': {
        fontSize: '16px',
        lineHeight: '24px',
    },
    '@tablet': {
        fontSize: '16px',
        lineHeight: '24px',
    },
});
export var SuccessButtonWrapper = styled('div', {
    display: 'flex',
    gap: '20px',
    '@mobile': {
        flexDirection: 'column',
        width: '100%',
        gap: '8px',
    },
    '@tablet': {
        flexDirection: 'column',
        width: '100%',
        gap: '8px',
    },
});
export var SuccessWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    alignItems: 'center',
    '@mobile': {
        gap: '40px',
    },
    '@tablet': {
        gap: '40px',
    },
});
export var WatchButton = styled(Button, {
    background: '#4B5768',
    color: '$alwaysWhite',
    padding: '12px 20px',
    height: '52px',
    gap: '8px',
    fontFamily: '$afacad',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '117%',
    '@mobile': {
        width: '100%',
        fontSize: '14px',
    },
    '@tablet': {
        width: '100%',
        fontSize: '14px',
    },
});
export var RocketImage = styled('img', {
    position: 'absolute',
    top: -344,
    left: -446,
    zIndex: 4,
    pointerEvents: 'none',
    userSelect: 'none',
    '@mobile': {
        display: 'none',
    },
    '@tablet': {
        display: 'none',
    },
});
export var BlueLight = styled('div', {
    WebkitBackfaceVisibility: 'hidden',
    WebkitPerspective: '1000',
    WebkitTransform: 'translate3d(0, 0, 0)',
    backfaceVisibility: 'hidden',
    perspective: '1000',
    transform: 'translate3d(0, 0, 0)',
    position: 'absolute',
    backgroundColor: '#00a5bb',
    width: '839px',
    height: '839px',
    borderRadius: '100%',
    filter: 'Blur(200px)',
    opacity: '40%',
    zIndex: '1',
    pointerEvents: 'none',
    userSelect: 'none',
    left: '52%',
    top: '-25%',
    '@mobile': {
        top: '0%',
        left: '40%',
    },
});
export var GreenLight = styled('div', {
    WebkitBackfaceVisibility: 'hidden',
    WebkitPerspective: '1000',
    WebkitTransform: 'translate3d(0, 0, 0)',
    backfaceVisibility: 'hidden',
    perspective: '1000',
    transform: 'translate3d(0, 0, 0)',
    position: 'absolute',
    backgroundColor: '#0b8216',
    width: '618px',
    height: '618px',
    borderRadius: '100%',
    filter: 'Blur(250px)',
    opacity: '40%',
    zIndex: '2',
    pointerEvents: 'none',
    userSelect: 'none',
    left: '20%',
    top: '13%',
    '@mobile': {
        left: '-40%',
    },
});
export var PurpleLight = styled('div', {
    WebkitBackfaceVisibility: 'hidden',
    WebkitPerspective: '1000',
    WebkitTransform: 'translate3d(0, 0, 0)',
    backfaceVisibility: 'hidden',
    perspective: '1000',
    transform: 'translate3d(0, 0, 0)',
    position: 'absolute',
    backgroundColor: '#DE06E2',
    width: '368px',
    height: '368px',
    borderRadius: '100%',
    filter: 'Blur(190px)',
    opacity: '30%',
    zIndex: '3',
    pointerEvents: 'none',
    userSelect: 'none',
    top: '47%',
    left: '67%',
});
export var MainBlueLight = styled('div', {
    WebkitBackfaceVisibility: 'hidden',
    WebkitPerspective: '1000',
    WebkitTransform: 'translate3d(0, 0, 0)',
    backfaceVisibility: 'hidden',
    perspective: '1000',
    transform: 'translate3d(0, 0, 0)',
    position: 'absolute',
    backgroundColor: '#00a5bb',
    width: '839px',
    height: '839px',
    borderRadius: '100%',
    filter: 'Blur(210px)',
    opacity: '40%',
    zIndex: '1',
    pointerEvents: 'none',
    userSelect: 'none',
    left: '52%',
    top: '-25%',
});
export var MainGreenLight = styled('div', {
    WebkitBackfaceVisibility: 'hidden',
    WebkitPerspective: '1000',
    WebkitTransform: 'translate3d(0, 0, 0)',
    backfaceVisibility: 'hidden',
    perspective: '1000',
    transform: 'translate3d(0, 0, 0)',
    position: 'absolute',
    backgroundColor: '#0b8216',
    width: '618px',
    height: '618px',
    borderRadius: '100%',
    filter: 'Blur(260px)',
    opacity: '80%',
    zIndex: '2',
    pointerEvents: 'none',
    userSelect: 'none',
    left: '32%',
    top: '13%',
    '@mobile': {
        left: '0%',
        filter: 'Blur(250px)',
        opacity: '40%',
    },
    '@tablet': {
        left: '0%',
        filter: 'Blur(250px)',
        opacity: '40%',
    },
});
export var MainPurpleLight = styled('div', {
    WebkitBackfaceVisibility: 'hidden',
    WebkitPerspective: '1000',
    WebkitTransform: 'translate3d(0, 0, 0)',
    backfaceVisibility: 'hidden',
    perspective: '1000',
    transform: 'translate3d(0, 0, 0)',
    position: 'absolute',
    backgroundColor: '#DE06E2',
    width: '368px',
    height: '368px',
    borderRadius: '100%',
    filter: 'Blur(190px)',
    opacity: '80%',
    zIndex: '3',
    pointerEvents: 'none',
    userSelect: 'none',
    top: '47%',
    left: '67%',
    '@mobile': {
        opacity: '60%',
    },
    '@tablet': {
        opacity: '60%',
    },
});
