export var RiskLevel;
(function (RiskLevel) {
    RiskLevel[RiskLevel["low"] = 1] = "low";
    RiskLevel[RiskLevel["medium"] = 2] = "medium";
    RiskLevel[RiskLevel["high"] = 3] = "high";
})(RiskLevel || (RiskLevel = {}));
export var backgroundVariants = [
    {
        blueLight: { left: '27%', top: '0%', width: '219px', height: '219px' },
        greenLight: { left: '0%', top: '33%', width: '161px', height: '161px' },
        purpleLight: { left: '10%', top: '55%', width: '96px', height: '96px' },
    },
    {
        blueLight: { left: '0%', top: '10%', width: '219px', height: '219px' },
        greenLight: { left: '50%', top: '25%', width: '161px', height: '161px' },
        purpleLight: { left: '30%', top: '60%', width: '96px', height: '96px' },
    },
    {
        blueLight: { left: '20%', top: '0%', width: '219px', height: '219px' },
        greenLight: { left: '0%', top: '33%', width: '161px', height: '161px' },
        purpleLight: { left: '43%', top: '60%', width: '96px', height: '96px' },
    },
    {
        blueLight: { left: '10%', top: '0%', width: '219px', height: '219px' },
        greenLight: { left: '45%', top: '40%', width: '161px', height: '161px' },
        purpleLight: { left: '43%', top: '0%', width: '96px', height: '96px' },
    },
    {
        blueLight: { left: '10%', top: '0%', width: '219px', height: '219px' },
        greenLight: { left: '0%', top: '40%', width: '161px', height: '161px' },
        purpleLight: { left: '60%', top: '40%', width: '96px', height: '96px' },
    },
    {
        blueLight: { left: '20%', top: '0%', width: '219px', height: '219px' },
        greenLight: { left: '30%', top: '50%', width: '161px', height: '161px' },
        purpleLight: { left: '7%', top: '34%', width: '96px', height: '96px' },
    },
    {
        blueLight: { left: '0%', top: '0%', width: '219px', height: '219px' },
        greenLight: { left: '40%', top: '15%', width: '161px', height: '161px' },
        purpleLight: { left: '43%', top: '55%', width: '96px', height: '96px' },
    },
    {
        blueLight: { left: '10%', top: '0%', width: '219px', height: '219px' },
        greenLight: { left: '0%', top: '20%', width: '161px', height: '161px' },
        purpleLight: { left: '47%', top: '10%', width: '96px', height: '96px' },
    },
    {
        blueLight: { left: '15%', top: '0%', width: '219px', height: '219px' },
        greenLight: { left: '40%', top: '10%', width: '161px', height: '161px' },
        purpleLight: { left: '15%', top: '15%', width: '96px', height: '96px' },
    },
    {
        blueLight: { left: '20%', top: '0%', width: '219px', height: '219px' },
        greenLight: { left: '10%', top: '27%', width: '161px', height: '161px' },
        purpleLight: { left: '55%', top: '60%', width: '96px', height: '96px' },
    },
];
