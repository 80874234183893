var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Content, Root, Trigger, Arrow, Provider, Portal } from './style';
import { forwardRef } from 'react';
export var Tooltip = forwardRef(function (props, ref) {
    var children = props.children, css = props.css, content = props.content, _a = props.side, side = _a === void 0 ? 'top' : _a, open = props.open, onOpenChange = props.onOpenChange, delayDuration = props.delayDuration, _b = props.type, type = _b === void 0 ? 'dark' : _b, _c = props.arrow, arrow = _c === void 0 ? true : _c, _d = props.sideOffset, sideOffset = _d === void 0 ? 0 : _d, _e = props.alignOffset, alignOffset = _e === void 0 ? 0 : _e, align = props.align;
    if (!content) {
        return _jsx(_Fragment, { children: children });
    }
    return (_jsx(Provider, __assign({ ref: ref }, props, { children: _jsxs(Root, __assign({ open: open, onOpenChange: onOpenChange, delayDuration: delayDuration }, { children: [_jsx(Trigger, __assign({ asChild: true }, { children: children })), _jsx(Portal, { children: _jsxs(Content, __assign({ align: align, alignOffset: alignOffset, sideOffset: sideOffset, theme: type, side: side, css: __assign(__assign({}, css), { whiteSpace: 'pre-line' }) }, { children: [content, arrow && _jsx(Arrow, { theme: type, offset: 10, height: 6, width: 10 })] })) })] })) })));
});
