import { toastError } from '@/widgets/ui/Toast/ToastError/ToastError';
var handlerErrors = function (errors) {
    var currentPage = window.location.pathname;
    var queryParams = new URLSearchParams(window.location.search);
    var disabledPages = ['/sign-in', '/sign-up'];
    if (currentPage.startsWith('/ticker/')) {
        return;
    }
    if (currentPage === '/settings' || ((queryParams.get('tab') === 'password' || queryParams.get('tab') === 'personalInfo'))) {
        return;
    }
    if (disabledPages.includes(currentPage)) {
        return;
    }
    if (errors && typeof errors === 'object' && !Array.isArray(errors)) {
        for (var key in errors) {
            if (Object.prototype.hasOwnProperty.call(errors, key)) {
                if (Array.isArray(errors[key])) {
                    for (var _i = 0, _a = errors[key]; _i < _a.length; _i++) {
                        var item = _a[_i];
                        if (typeof item === 'string') {
                            toastError(item, 5000, 'bottom-left');
                        }
                    }
                }
                else if (typeof errors[key] === 'string') {
                    toastError(errors[key], 5000, 'bottom-left');
                }
            }
        }
    }
    else {
        toastError('Something went wrong', 5000, 'bottom-left');
    }
};
export default handlerErrors;
