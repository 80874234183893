import { styled } from '@stitches/react';
export var InformerWrapper = styled('div', {
    display: 'flex',
    gap: '12px',
    padding: '20px',
    borderRadius: '20px',
    alignItems: 'center',
    boxSizing: 'border-box',
    variants: {
        type: {
            attention: {
                border: '1px solid $controlsStrokeAttention',
                background: '$bgAttentionLight',
                alignItems: 'center',
            },
            info: {
                border: '1px solid $controlsBgAccentDefault',
                background: '$bgAccent',
            },
            warning: {
                border: '1px solid $controlsStrokeError',
                background: '$bgError',
            },
        },
        alignIcon: {
            start: {
                alignItems: 'start',
            },
            center: {
                alignItems: 'center',
            },
            end: {
                alignItems: 'end',
            },
        },
    },
});
export var InformerButton = styled('p', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 8px !important',
    height: '36px',
    flexShrink: 0,
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px',
    color: '$textLinkDefault',
});
export var ContentWrapper = styled('div', {
    display: 'flex',
    gap: '40px',
});
